import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'

// @ts-ignore:

import moment from 'moment'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

export const StatusOrder = forwardRef((props: any, ref: any) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className={`c-pointer ${!value.is_deleted || 'bg-danger bg-opacity-20'}`}
        >
          <td style={{width: 40}}>{value.id}</td>
          <td>{value.description}</td>
          <td>{(value.user || {}).name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{moment(value.date).format('DD/MM/YYYY HH:mm')}</td>
          <td>{arr[0].system}</td>
        </tr>
      )
    }

    return content
  }

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.STATUS'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='text-center' style={{width: 40}}>
                      ID
                    </th>
                    <th>{intl.formatMessage({id: 'COMMON.DESCRIPTION'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.CREATED'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.SYSTEM'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(props.data.order_logs)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})
