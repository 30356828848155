import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {convertToPrice, getMediaUrl} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import FooterCopyright from '../../../modules/FooterCopyright'

export function AccountViewIndex() {
  let navigate = useNavigate()
  const intl = useIntl()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  let {id} = useParams()
  const {classes} = useLayout()

  const [data, setData] = React.useState<any>({})
  const [statuses, setStatuses] = React.useState([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function getData() {
    try {
      const res = await axios.get(`accounts/${id}`)
      setData(res.data || {})
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  React.useEffect(() => {
    if (!id) {
      navigate('/error/404')
      return
    }
    setIsLoading(true)

    getData()
    getStatuses()
    getPaymentTypes()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  async function getStatuses() {
    try {
      const res = await axios.get('/statuses/')

      setStatuses(res.data.data)
    } catch (err: any) {
      console.log('error', err)
    }
  }
  async function getPaymentTypes() {
    try {
      const res = await axios.get('/payment-types/')

      setPaymentTypes(res.data.data)
    } catch (err: any) {
      console.log('error', err)
    }
  }
  async function setBlocked() {
    // try {
    //   const res = await axios.put(`accounts/block/${id}?value=1`)
    // } catch (err: any) {
    //   console.log('error', err)
    //   setError(err)
    // }
    try {
      const res = await axios.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: true,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }
  async function setUnBlocked() {
    try {
      const res = await axios.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: false,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }

  const getOrdersTableContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer text-nowrap'
          onClick={() => {
            // navigate(`/orders/view/${value.id}`)
            navigate(`/orders/edit-order?orderId=${value.id}`)
            return
          }}
        >
          <td>{value.id}</td>
          <td>{value.number}</td>
          <td>{value.system}</td>
          <td>{value.name}</td>
          <td>{value.phone}</td>
          <td>{moment(value.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{(value.terminal || {}).name_ru}</td>
          <td>
            {/* @ts-ignore: */}
            {(
              paymentTypes.find((x: any) => x.code == (value.payment_type || {}).code) || {
                name_ru: '',
              }
            ).name_ru || 'Не задано'}
          </td>
          <td>
            {convertToPrice(value.full_sum)} {intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
          <td>
            <span className='badge badge-light-success'>
              {/* @ts-ignore: */}
              {(statuses.find((x: any) => x.id == value.status) || {}).description_ru ||
                intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
            </span>
          </td>
        </tr>
      )
    }

    return content
  }

  const getLanguagesName = (name: string) => {
    if (name == 'ru') return intl.formatMessage({id: 'COMMON.LANG.RU'})
    if (name == 'en') return intl.formatMessage({id: 'COMMON.LANG.EN'})
    return intl.formatMessage({id: 'COMMON.LANG.UZ'})
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: `${intl.formatMessage({id: 'MENU.CLIENT'})}`,
        path: '/accounts',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{data?.name}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div
          className='h-30 card-body main-overflow-x'
          // style={{height: windowSize.current[1] - 140}}
        >
          <div className='d-flex flex-column flex-xl-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-xl-350px'>
              <div className='card mb-5 mb-xl-8'>
                <div className='card-body pt-10'>
                  <div className='d-flex flex-center flex-column'>
                    <div className='symbol symbol-150px symbol-circle mb-7'>
                      <img
                        src={
                          data?.image
                            ? getMediaUrl('users', data?.image)
                            : toAbsoluteUrl('/avatar.png')
                        }
                        alt={data?.name}
                      />
                    </div>
                    <div className='fs-3 text-gray-800  mb-1'>{data?.name}</div>
                    <div className='fs-5 fw-semibold text-muted mb-1'>+{data?.phone}</div>
                    <div className='fs-5 fw-semibold text-muted mb-6 text-center'>
                      Дата последнего заказа: 01.01.1970 - 13 дней назад
                    </div>
                  </div>
                  <div className='d-flex flex-stack fs-4 py-3'>
                    <div className=''>{intl.formatMessage({id: 'COMMON.BLOCKED'})}</div>
                    <div
                      className={clsx(
                        'badge d-inline',
                        {'badge-success': data?.is_blocked},
                        {
                          'badge-danger': !data?.is_blocked,
                        }
                      )}
                    >
                      {data.is_blocked
                        ? intl.formatMessage({id: 'COMMON.YES'})
                        : intl.formatMessage({id: 'COMMON.NO'})}
                    </div>
                  </div>
                  <div className='separator separator-dashed my-1' />
                  <div className='pb-3 fs-6'>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.ACCOUNT_ID'})}</div>
                    <div className='text-gray-600'>{data?.id}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.REGION'})}</div>
                    <div className='text-gray-600'>{data?.region?.name || '-'}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.BIRTH_DATE'})}</div>
                    <div className='text-gray-600'>
                      {data?.birthday ? moment(data?.birthday).format('DD/MM/YYYY') : '-'}
                    </div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.GENDER'})}</div>
                    <div className='text-gray-600'>
                      {data?.gender == 0
                        ? intl.formatMessage({id: 'COMMON.FEMALE'})
                        : intl.formatMessage({id: 'COMMON.MALE'})}
                    </div>
                    {/* <div className=' mt-5'>Delivery Address</div>
                    <div className='text-gray-600'>
                      101 Collin Street, <br />
                      Melbourne 3000 VIC
                      <br />
                      Australia
                    </div> */}
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.LANGUAGE'})}</div>
                    <div className='text-gray-600'>{getLanguagesName(data.language)}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.CREATED_AT'})}</div>
                    <div className='text-gray-600'>{moment(data.date).format('DD/MM/YYYY')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-lg-row-fluid ms-lg-5'>
              <ul
                className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-4'
                role='tablist'
              >
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link text-active-primary pb-2 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_customer_overview'
                    aria-selected='true'
                    role='tab'
                  >
                    {intl.formatMessage({id: 'COMMON.OVERVIEW'})}
                  </a>
                </li>
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link text-active-primary pb-2'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_customer_general'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'
                  >
                    {intl.formatMessage({id: 'COMMON.HISTORY_OF_ORDERS'})}
                  </a>
                </li>
                {/* <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link text-active-primary pb-2'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_customer_advanced'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'
                  >
                    Advanced Settings
                  </a>
                </li> */}
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_customer_overview'
                  role='tabpanel'
                >
                  <div className='row row-cols-1 row-cols-md-2 mb-6 mb-xl-9'>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_count || 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.PCS'})}
                          </h2>
                          <div className='  text-muted'>
                            {intl.formatMessage({id: 'COMMON.ORDERS_COUNT'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {convertToPrice(Math.round(data?.order_total || 0)) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className='  text-muted'>
                            {intl.formatMessage({id: 'COMMON.TOTAL_BUY_SUM'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_avg ? convertToPrice(Math.round(data?.order_avg)) : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className=' fs-2'>
                            <div className='fs-7 fw-normal text-muted'>
                              {intl.formatMessage({id: 'COMMON.AVG_CHECK'})}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_processed_count ? data?.order_processed_count : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.PCS'})}
                          </h2>

                          <div className='text-muted'>
                            {intl.formatMessage({id: 'COMMON.PROCESSED_ORDERS'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_max ? convertToPrice(Math.round(data?.order_max)) : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className='text-muted'>
                            {intl.formatMessage({id: 'COMMON.EXPENSIVE_ORDER'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_min ? convertToPrice(Math.round(data?.order_min)) : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className='text-muted'>
                            {intl.formatMessage({id: 'COMMON.CHEAP_ORDER'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_canceled_count ? data?.order_canceled_count : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.PCS'})}
                          </h2>
                          <div className='fs-7 fw-normal text-muted'>
                            {intl.formatMessage({id: 'COMMON.CANCELED_ORDERS'})}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_ecommerce_customer_general' role='tabpanel'>
                  <div className='card mb-xl-9'>
                    <div className='card-header border-0'>
                      <div className='card-title'>
                        <h2>{intl.formatMessage({id: 'COMMON.HISTORY_OF_ORDERS'})}</h2>
                      </div>
                    </div>
                    <div className='card-body py-0 pb-5'>
                      <div className='table-responsive'>
                        <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                          <thead>
                            <tr className=' bg-light'>
                              <th>ID</th>
                              <th className='min-w-10px'>
                                {intl.formatMessage({id: 'COMMON.CHEQUE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.SYSTEM'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.CLIENT'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.PHONE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.DATE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.BRANCH'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.TOTAL'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.STATUS'})}
                              </th>
                            </tr>
                          </thead>
                          {/* @ts-ignore: */}
                          <tbody>{getOrdersTableContent(data.orders || [])}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='card card-body pt-0 pb-5 mb-xl-9'>
                    <div className='card-header border-0 p-0'>
                      <div className='card-title'>
                        <h2>{intl.formatMessage({id: 'COMMON.HISTORY_OF_ORDERS'})}</h2>
                      </div>
                    </div>
                    <div
                      id='kt_table_customers_payment_wrapper'
                      className='dt-container dt-bootstrap5 dt-empty-footer'
                    >
                      <div id='' className='table-responsive'>
                        <table
                          className='table align-middle table-row-dashed gy-5 dataTable'
                          id='kt_table_customers_payment'
                          style={{width: '738.55px'}}
                        >
                          <colgroup>
                            <col style={{width: 142}} />
                            <col style={{width: '130.075px'}} />
                            <col style={{width: '113.888px'}} />
                            <col style={{width: 142}} />
                            <col style={{width: '210.588px'}} />
                          </colgroup>
                          <thead className='border-bottom border-gray-200 fs-7 '>
                            <tr className='text-start text-muted text-uppercase gs-0' role='row'>
                              <th className='w-100px'>ID</th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.CHEQUE'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.SYSTEM'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.CLIENT'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title'>
                                  {intl.formatMessage({id: 'COMMON.PHONE'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.DATE'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.BRANCH'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.TOTAL'})}
                                </span>
                              </th>
                              <th className='w-100px'>
                                <span className='dt-column-title' role='button'>
                                  {intl.formatMessage({id: 'COMMON.STATUS'})}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='fs-6 fw-semibold text-gray-600'>
                            {getOrdersTableContent(data.orders || [])}
                          </tbody>
                          <tfoot />
                        </table>
                      </div>
                      {/* <div id='' className='row'>
                          <div
                            id=''
                            className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                          />
                          <div
                            id=''
                            className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                          >
                            <div className='dt-paging paging_simple_numbers'>
                              <ul className='pagination'>
                                <li className='dt-paging-button page-item disabled'>
                                  <a
                                    className='page-link previous'
                                    aria-controls='kt_table_customers_payment'
                                    aria-disabled='true'
                                    aria-label='Previous'
                                    data-dt-idx='previous'
                                    tabIndex={-1}
                                  >
                                    <i className='previous' />
                                  </a>
                                </li>
                                <li className='dt-paging-button page-item active'>
                                  <a
                                    href='#'
                                    className='page-link'
                                    aria-controls='kt_table_customers_payment'
                                    aria-current='page'
                                    data-dt-idx={0}
                                    tabIndex={0}
                                  >
                                    1
                                  </a>
                                </li>
                                <li className='dt-paging-button page-item'>
                                  <a
                                    href='#'
                                    className='page-link'
                                    aria-controls='kt_table_customers_payment'
                                    data-dt-idx={1}
                                    tabIndex={0}
                                  >
                                    2
                                  </a>
                                </li>
                                <li className='dt-paging-button page-item'>
                                  <a
                                    href='#'
                                    className='page-link next'
                                    aria-controls='kt_table_customers_payment'
                                    aria-label='Next'
                                    data-dt-idx='next'
                                    tabIndex={0}
                                  >
                                    <i className='next' />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_ecommerce_customer_advanced' role='tabpanel'>
                  <div className='card pt-4 mb-6 mb-xl-9'>
                    <div className='card-header border-0'>
                      <div className='card-title'>
                        <h2>Security Details</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0 pb-5'>
                      <div className='table-responsive'>
                        <table
                          className='table align-middle table-row-dashed gy-5'
                          id='kt_table_users_login_session'
                        >
                          <tbody className='fs-6 fw-semibold text-gray-600'>
                            <tr>
                              <td>Phone</td>
                              <td>+6141 234 567</td>
                              <td className='text-end'>
                                <button
                                  type='button'
                                  className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_update_phone'
                                >
                                  <i className='ki-duotone ki-pencil fs-3'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>Password</td>
                              <td>******</td>
                              <td className='text-end'>
                                <button
                                  type='button'
                                  className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_update_password'
                                >
                                  <i className='ki-duotone ki-pencil fs-3'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='card pt-4 mb-6 mb-xl-9'>
                    <div className='card-header border-0'>
                      <div className='card-title flex-column'>
                        <h2 className='mb-1'>Two Step Authentication</h2>
                        <div className='fs-6 fw-semibold text-muted'>
                          Keep your account extra secure with a second authentication step.
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <button
                          type='button'
                          className='btn btn-light-primary btn-sm'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          <i className='ki-duotone ki-fingerprint-scanning fs-3'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                            <span className='path4' />
                            <span className='path5' />
                          </i>
                          Add Authentication Step
                        </button>
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-6 w-200px py-4'
                          data-kt-menu='true'
                        >
                          <div className='menu-item px-3'>
                            <a
                              href='#'
                              className='menu-link px-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_add_auth_app'
                            >
                              Use authenticator app
                            </a>
                          </div>
                          <div className='menu-item px-3'>
                            <a
                              href='#'
                              className='menu-link px-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_add_one_time_password'
                            >
                              Enable one-time password
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-body pb-5'>
                      <div className='d-flex flex-stack'>
                        <div className='d-flex flex-column'>
                          <span>SMS</span>
                          <span className='text-muted fs-6'>+6141 234 567</span>
                        </div>
                        <div className='d-flex justify-content-end align-items-center'>
                          <button
                            type='button'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto me-5'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_add_one_time_password'
                          >
                            <i className='ki-duotone ki-pencil fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                            id='kt_users_delete_two_step'
                          >
                            <i className='ki-duotone ki-trash fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </button>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5' />
                      <div className='text-gray-600'>
                        If you lose your mobile device or security key, you can
                        <a href='#' className='me-1'>
                          generate a backup code
                        </a>
                        to sign in to your account.
                      </div>
                    </div>
                  </div>
                  <div className='card pt-4 mb-6 mb-xl-9'>
                    <div className='card-header border-0'>
                      <div className='card-title'>
                        <h2 className=' mb-0'>Payment Methods</h2>
                      </div>
                      <div className='card-toolbar'>
                        <a
                          href='#'
                          className='btn btn-sm btn-flex btn-light-primary'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_new_card'
                        >
                          <i className='ki-duotone ki-plus-square fs-3'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                          Add new method
                        </a>
                      </div>
                    </div>
                    <div id='kt_customer_view_payment_method' className='card-body pt-0'>
                      <div
                        className='accordion accordion-icon-toggle'
                        id='kt_customer_view_payment_method_accordion'
                      >
                        <div className='py-0' data-kt-customer-payment-method='row'>
                          <div className='py-3 d-flex flex-stack flex-wrap'>
                            <div
                              className='accordion-header d-flex align-items-center '
                              data-bs-toggle='collapse'
                              // href="#kt_customer_view_payment_method_1"
                              role='button'
                              aria-expanded='false'
                              aria-controls='kt_customer_view_payment_method_1'
                            >
                              <div className='accordion-icon me-2'>
                                <i className='ki-duotone ki-right fs-4' />
                              </div>
                              <img
                                src='/metronic8/demo8/assets/media/svg/card-logos/mastercard.svg'
                                className='w-40px me-3'
                                alt=''
                              />
                              <div className='me-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='text-gray-800 '>Mastercard </div>
                                  <div className='badge badge-light-primary ms-5'>Primary</div>
                                </div>
                                <div className='text-muted'>Expires Dec 2024</div>
                              </div>
                            </div>
                            <div className='d-flex my-3 ms-9'>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_new_card'
                              >
                                <span
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  aria-label='Edit'
                                  data-bs-original-title='Edit'
                                  data-kt-initialized={1}
                                >
                                  <i className='ki-duotone ki-pencil fs-3'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </span>
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                data-bs-toggle='tooltip'
                                data-kt-customer-payment-method='delete'
                                aria-label='Delete'
                                data-bs-original-title='Delete'
                                data-kt-initialized={1}
                              >
                                <i className='ki-duotone ki-trash fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px'
                                data-bs-toggle='tooltip'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                aria-label='More Options'
                                data-bs-original-title='More Options'
                                data-kt-initialized={1}
                              >
                                <i className='ki-duotone ki-setting-3 fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-3'
                                data-kt-menu='true'
                              >
                                <div className='menu-item px-3'>
                                  <a
                                    href='#'
                                    className='menu-link px-3'
                                    data-kt-payment-mehtod-action='set_as_primary'
                                  >
                                    Set as Primary
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id='kt_customer_view_payment_method_1'
                            className='collapse show fs-6 ps-10'
                            data-bs-parent='#kt_customer_view_payment_method_accordion'
                          >
                            <div className='d-flex flex-wrap py-5'>
                              <div className='flex-equal me-5'>
                                <table className='table table-flush fw-semibold gy-1'>
                                  <tbody>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Name</td>
                                      <td className='text-gray-800'>Emma Smith</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Number</td>
                                      <td className='text-gray-800'>**** 4425</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Expires</td>
                                      <td className='text-gray-800'>12/2024</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Type</td>
                                      <td className='text-gray-800'>Mastercard credit card</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Issuer</td>
                                      <td className='text-gray-800'>VICBANK</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>ID</td>
                                      <td className='text-gray-800'>id_4325df90sdf8</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='flex-equal '>
                                <table className='table table-flush fw-semibold gy-1'>
                                  <tbody>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>
                                        Billing address
                                      </td>
                                      <td className='text-gray-800'>AU</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Phone</td>
                                      <td className='text-gray-800'>No phone provided</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Email</td>
                                      <td className='text-gray-800'>
                                        <a href='#' className='text-gray-900 text-hover-primary'>
                                          smith@kpmg.com
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Origin</td>
                                      <td className='text-gray-800'>
                                        Australia
                                        <div className='symbol symbol-20px symbol-circle ms-2'>
                                          <img src='/metronic8/demo8/assets/media/flags/australia.svg' />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>CVC check</td>
                                      <td className='text-gray-800'>
                                        Passed
                                        <i className='ki-duotone ki-check-circle fs-2 text-success'>
                                          <span className='path1' />
                                          <span className='path2' />
                                        </i>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed' />
                        <div className='py-0' data-kt-customer-payment-method='row'>
                          <div className='py-3 d-flex flex-stack flex-wrap'>
                            <div
                              className='accordion-header d-flex align-items-center collapsed'
                              data-bs-toggle='collapse'
                              // href="#kt_customer_view_payment_method_2"
                              role='button'
                              aria-expanded='false'
                              aria-controls='kt_customer_view_payment_method_2'
                            >
                              <div className='accordion-icon me-2'>
                                <i className='ki-duotone ki-right fs-4' />
                              </div>
                              <img
                                src='/metronic8/demo8/assets/media/svg/card-logos/visa.svg'
                                className='w-40px me-3'
                                alt=''
                              />
                              <div className='me-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='text-gray-800 '>Visa </div>
                                </div>
                                <div className='text-muted'>Expires Feb 2022</div>
                              </div>
                            </div>
                            <div className='d-flex my-3 ms-9'>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_new_card'
                              >
                                <span
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  aria-label='Edit'
                                  data-bs-original-title='Edit'
                                  data-kt-initialized={1}
                                >
                                  <i className='ki-duotone ki-pencil fs-3'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </span>
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                data-bs-toggle='tooltip'
                                data-kt-customer-payment-method='delete'
                                aria-label='Delete'
                                data-bs-original-title='Delete'
                                data-kt-initialized={1}
                              >
                                <i className='ki-duotone ki-trash fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px'
                                data-bs-toggle='tooltip'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                aria-label='More Options'
                                data-bs-original-title='More Options'
                                data-kt-initialized={1}
                              >
                                <i className='ki-duotone ki-setting-3 fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-3'
                                data-kt-menu='true'
                              >
                                <div className='menu-item px-3'>
                                  <a
                                    href='#'
                                    className='menu-link px-3'
                                    data-kt-payment-mehtod-action='set_as_primary'
                                  >
                                    Set as Primary
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id='kt_customer_view_payment_method_2'
                            className='collapse  fs-6 ps-10'
                            data-bs-parent='#kt_customer_view_payment_method_accordion'
                          >
                            <div className='d-flex flex-wrap py-5'>
                              <div className='flex-equal me-5'>
                                <table className='table table-flush fw-semibold gy-1'>
                                  <tbody>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Name</td>
                                      <td className='text-gray-800'>Melody Macy</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Number</td>
                                      <td className='text-gray-800'>**** 5049</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Expires</td>
                                      <td className='text-gray-800'>02/2022</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Type</td>
                                      <td className='text-gray-800'>Visa credit card</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Issuer</td>
                                      <td className='text-gray-800'>ENBANK</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>ID</td>
                                      <td className='text-gray-800'>id_w2r84jdy723</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='flex-equal '>
                                <table className='table table-flush fw-semibold gy-1'>
                                  <tbody>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>
                                        Billing address
                                      </td>
                                      <td className='text-gray-800'>UK</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Phone</td>
                                      <td className='text-gray-800'>No phone provided</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Email</td>
                                      <td className='text-gray-800'>
                                        <a href='#' className='text-gray-900 text-hover-primary'>
                                          melody@altbox.com
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Origin</td>
                                      <td className='text-gray-800'>
                                        United Kingdom
                                        <div className='symbol symbol-20px symbol-circle ms-2'>
                                          <img src='/metronic8/demo8/assets/media/flags/united-kingdom.svg' />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>CVC check</td>
                                      <td className='text-gray-800'>
                                        Passed
                                        <i className='ki-duotone ki-check fs-2 text-success' />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed' />
                        <div className='py-0' data-kt-customer-payment-method='row'>
                          <div className='py-3 d-flex flex-stack flex-wrap'>
                            <div
                              className='accordion-header d-flex align-items-center collapsed'
                              data-bs-toggle='collapse'
                              // href="#kt_customer_view_payment_method_3"
                              role='button'
                              aria-expanded='false'
                              aria-controls='kt_customer_view_payment_method_3'
                            >
                              <div className='accordion-icon me-2'>
                                <i className='ki-duotone ki-right fs-4' />
                              </div>
                              <img
                                src='/metronic8/demo8/assets/media/svg/card-logos/american-express.svg'
                                className='w-40px me-3'
                                alt=''
                              />
                              <div className='me-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='text-gray-800 '>American Express </div>
                                  <div className='badge badge-light-danger ms-5'>Expired</div>
                                </div>
                                <div className='text-muted'>Expires Aug 2021</div>
                              </div>
                            </div>
                            <div className='d-flex my-3 ms-9'>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_new_card'
                              >
                                <span
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  aria-label='Edit'
                                  data-bs-original-title='Edit'
                                  data-kt-initialized={1}
                                >
                                  <i className='ki-duotone ki-pencil fs-3'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </span>
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                data-bs-toggle='tooltip'
                                data-kt-customer-payment-method='delete'
                                aria-label='Delete'
                                data-bs-original-title='Delete'
                                data-kt-initialized={1}
                              >
                                <i className='ki-duotone ki-trash fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-active-light-primary w-30px h-30px'
                                data-bs-toggle='tooltip'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                aria-label='More Options'
                                data-bs-original-title='More Options'
                                data-kt-initialized={1}
                              >
                                <i className='ki-duotone ki-setting-3 fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-3'
                                data-kt-menu='true'
                              >
                                <div className='menu-item px-3'>
                                  <a
                                    href='#'
                                    className='menu-link px-3'
                                    data-kt-payment-mehtod-action='set_as_primary'
                                  >
                                    Set as Primary
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id='kt_customer_view_payment_method_3'
                            className='collapse  fs-6 ps-10'
                            data-bs-parent='#kt_customer_view_payment_method_accordion'
                          >
                            <div className='d-flex flex-wrap py-5'>
                              <div className='flex-equal me-5'>
                                <table className='table table-flush fw-semibold gy-1'>
                                  <tbody>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Name</td>
                                      <td className='text-gray-800'>Max Smith</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Number</td>
                                      <td className='text-gray-800'>**** 6998</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Expires</td>
                                      <td className='text-gray-800'>08/2021</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Type</td>
                                      <td className='text-gray-800'>
                                        American express credit card
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Issuer</td>
                                      <td className='text-gray-800'>USABANK</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>ID</td>
                                      <td className='text-gray-800'>id_89457jcje63</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='flex-equal '>
                                <table className='table table-flush fw-semibold gy-1'>
                                  <tbody>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>
                                        Billing address
                                      </td>
                                      <td className='text-gray-800'>US</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Phone</td>
                                      <td className='text-gray-800'>No phone provided</td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Email</td>
                                      <td className='text-gray-800'>
                                        <a href='#' className='text-gray-900 text-hover-primary'>
                                          max@kt.com
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>Origin</td>
                                      <td className='text-gray-800'>
                                        United States of America
                                        <div className='symbol symbol-20px symbol-circle ms-2'>
                                          <img src='/metronic8/demo8/assets/media/flags/united-states.svg' />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-muted min-w-125px w-125px'>CVC check</td>
                                      <td className='text-gray-800'>
                                        Failed
                                        <i className='ki-duotone ki-cross fs-2 text-danger'>
                                          <span className='path1' />
                                          <span className='path2' />
                                        </i>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
