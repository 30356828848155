import axios from 'axios'
import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore
import 'react-datepicker/dist/react-datepicker.css'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'
import {MapModal} from './MapModal'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()
  const mapModalRef = useRef()

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    key: Yup.string(),
    latitude: Yup.string(),
    longitude: Yup.string(),
    is_main: Yup.number().integer(),
  })

  let initialValues = {
    latitude: '',
    longitude: '',
    name: '',
    key: '',
    is_main: 0,
  }
  if (!!props.item && !!props.item.id) {
    initialValues.latitude = props.item.latitude
    initialValues.longitude = props.item.longitude
    initialValues.name = props.item.name
    initialValues.key = props.item.key
    initialValues.is_main = props.item.is_main
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(
          `/organization/${props.item.id}`,
          {
            id: props.item.id,
            latitude: values.latitude || '',
            longitude: values.longitude || '',
            name: values.name,
            key: values.key,
            // @ts-ignore:
            is_main: parseInt(values.is_main || '0', 10),
            type: props.item.type,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                value={formik.values.name}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.KEY'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('key')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.key && formik.errors.key},
                  {
                    'is-valid': formik.touched.key && !formik.errors.key,
                  }
                )}
                value={formik.values.key}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'MENU.LOCATION'})}</label>

              <button
                className='btn btn-outline-primary active form-control'
                onClick={() => {
                  // @ts-ignore
                  mapModalRef.current.showModal()
                }}
              >
                {intl.formatMessage({id: 'COMMON.POINT_ON_MAP'})}
              </button>
            </div>
            {/* <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.LATITUDE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('latitude')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.latitude && formik.errors.latitude},
                  {
                    'is-valid': formik.touched.latitude && !formik.errors.latitude,
                  }
                )}
                value={formik.values.latitude}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.LONGITUDE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('longitude')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.longitude && formik.errors.longitude},
                  {
                    'is-valid': formik.touched.longitude && !formik.errors.longitude,
                  }
                )}
                value={formik.values.longitude}
              />
            </div> */}
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.MAIN'})}</label>

              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  <label
                    className={clsx('btn btn-outline btn-color-muted p-2', {
                      active: formik.values.is_main == 1,
                      'btn-active-light-success': true,
                    })}
                    data-kt-button='true'
                  >
                    <input
                      className='btn-check'
                      type='radio'
                      name='RadioIsMain'
                      value={1}
                      checked={formik.values.is_main == 1}
                      onChange={(e) => formik.setFieldValue('is_main', e.currentTarget.value)}
                    />
                    {intl.formatMessage({id: 'COMMON.YES'})}
                  </label>
                  <label
                    className={clsx('btn btn-outline btn-color-muted p-2', {
                      active: formik.values.is_main == 0,
                      'btn-active-light-danger': true,
                    })}
                    data-kt-button='true'
                  >
                    <input
                      className='btn-check'
                      type='radio'
                      name='RadioIsMain'
                      value={0}
                      checked={formik.values.is_main == 0}
                      onChange={(e) => formik.setFieldValue('is_main', e.currentTarget.value)}
                    />
                    {intl.formatMessage({id: 'COMMON.NO'})}
                  </label>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {(props.item?.status || 0) == 0 && (
            <></>
            // <button
            //   type='button'
            //   className='btn btn-danger btn-sm me-auto'
            //   // @ts-ignore
            //   onClick={() => (handleClose(), deleteModalRef.current.showModal())}
            // >
            //   {intl.formatMessage({id: 'COMMON.DELETE'})}
            // </button>
          )}

          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>

          {(props.item?.status || 0) == 0 && (
            <button
              type='button'
              className='btn btn-success'
              onClick={() => {
                formik.handleSubmit()
              }}
              disabled={loading}
              data-kt-indicator={loading == true ? 'on' : 'off'}
            >
              <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

              <span className='indicator-progress'>
                {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
      <MapModal
        item={props.item}
        ref={mapModalRef}
        refreshData={props.refreshData}
        formik={formik}
      />
    </>
  )
})
