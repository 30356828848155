import {format, parseISO} from 'date-fns'

export const dateTimeFormat = (date: string): string => {
  if (!date || date === '') {
    return '-'
  }
  const dateISO = parseISO(date)
  return format(dateISO, 'dd.MM.yyyy HH:mm')
}

export const getOrderTypeText = (type: boolean): string => {
  return type ? 'Самовывоз' : 'Доставка'
}

export const getPriceFormatted = (price: number): string => {
  return new Intl.NumberFormat('ru-RU').format(price)
}
export const getDistanceInKm = (distance: number): string => {
  return (Math.round((distance / 1000 + Number.EPSILON) * 100) / 100).toString()
}
