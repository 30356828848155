import {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

export function RedirectCalls() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const caller = searchParams.get('caller')
  let phone = caller
  if (phone.length === 9) {
    phone = `998${phone}`
  } else {
    const phoneMatch = caller.match(/998\d{9}/)
    phone = phoneMatch ? phoneMatch[0] : null
  }

  useEffect(() => {
    if (!phone) {
      navigate(`/orders/create-order`)
      return
    }

    navigate(`/orders/create-order?phone=${phone}`)
  }, [phone])

  return (
    <>
      <div>calls redirect</div>
    </>
  )
}
