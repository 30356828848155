import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore:
import InputMask from 'react-input-mask'
// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'

import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {clearPhoneNumber, IsValidPhoneFormat} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    phone: Yup.string().test(
      'phone-format',
      intl.formatMessage({id: 'VALIDATION.PHONE'}),
      function (value) {
        // @ts-ignore:
        return !!IsValidPhoneFormat(value)
      }
    ),
    birthdate: Yup.date().required(),
    gender: Yup.number().integer().required(),
    language: Yup.string().nullable(),
  })

  let initialValues = {
    name: '',
    phone: '+998',
    birthdate: '',
    language: '',
    gender: 1,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      let phone = ''
      if (values.phone.length > 4) {
        phone = clearPhoneNumber(values.phone)
      }

      try {
        const res = await axios.post(
          '/accounts/',
          {
            name: values.name,
            birthday: !!values.birthdate ? moment(values.birthdate).format() : null,
            phone: `+${phone}`,
            language: values.language,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
        setLoading(false)
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)

        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const languagesConstant = [
    {id: 'ru', name: intl.formatMessage({id: 'COMMON.LANG.RU'})},
    {id: 'uz', name: intl.formatMessage({id: 'COMMON.LANG.UZ'})},
    {id: 'en', name: intl.formatMessage({id: 'COMMON.LANG.EN'})},
  ]

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered className='es-modal'>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.FULLNAME'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                value={formik.values.name}
                autoComplete='off'
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
              <InputMask
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.phone && formik.errors.phone},
                  {
                    'is-valid': formik.touched.phone && !formik.errors.phone,
                  }
                )}
                mask='+999 (99) 999-99-99'
                // @ts-ignore:
                onChange={(e) => formik.setFieldValue('phone', e.currentTarget.value)}
                value={formik.values.phone}
                autoComplete='off'
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.BIRTH_DATE'})}</label>
              <input
                type='date'
                {...formik.getFieldProps('birthdate')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.birthdate && formik.errors.birthdate},
                  {
                    'is-valid': formik.touched.birthdate && !formik.errors.birthdate,
                  }
                )}
                value={formik.values.birthdate}
                autoComplete='off'
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.LANG'})}</label>
              <Select
                // classNamePrefix='form-control'
                styles={selectStyles}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () =>
                    formik.touched.language && !formik.errors.language
                      ? 'form-select p-0 border-success'
                      : formik.touched.language && formik.errors.language
                      ? 'form-select p-0 border-danger'
                      : 'form-select p-0',
                }}
                options={languagesConstant.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                onChange={(selectedItem: any) => {
                  formik.setFieldValue('language', selectedItem.value)
                }}
                value={{
                  value:
                    (languagesConstant.find((x: any) => x.id == formik.values.language) || {}).id ||
                    null,
                  label:
                    (languagesConstant.find((x: any) => x.id == formik.values.language) || {})
                      .name || '',
                }}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.GENDER'})}</label>

              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  <label
                    className={clsx(
                      'btn btn-outline btn-color-muted btn-active-light-primary p-2',
                      {
                        active: formik.values.gender == 1,
                      }
                    )}
                    data-kt-button='true'
                  >
                    <input
                      className='btn-check'
                      type='radio'
                      name='RadioGender'
                      value={1}
                      checked={formik.values.gender == 1}
                      onChange={(e) => formik.setFieldValue('gender', e.currentTarget.value)}
                    />
                    {intl.formatMessage({id: 'COMMON.MALE'})}
                  </label>

                  <label
                    className={clsx('btn btn-outline btn-color-muted btn-active-light-danger p-2', {
                      active: formik.values.gender == 2,
                    })}
                    data-kt-button='true'
                  >
                    <input
                      className='btn-check'
                      type='radio'
                      name='RadioGender'
                      value={2}
                      checked={formik.values.gender == 2}
                      onChange={(e) => formik.setFieldValue('gender', e.currentTarget.value)}
                    />
                    {intl.formatMessage({id: 'COMMON.FEMALE'})}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
