import axios from 'axios'
import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {swal} from '../../../../_metronic/helpers/swal'

import {MAIN_STATUSES, swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    name_ru: Yup.string()
      .required()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    name_uz: Yup.string().nullable(),
    name_en: Yup.string().nullable(),
    code: Yup.string().nullable(),
    is_active: Yup.number().integer(),
    is_online: Yup.number().integer(),
  })

  const initialValues = {
    code: '',
    name_en: '',
    name_ru: '',
    name_uz: '',
    is_active: 1,
    is_online: 0,
  }

  if (!!props.item && !!props.item.id) {
    initialValues.code = props.item.code
    initialValues.name_en = props.item.name_en
    initialValues.name_ru = props.item.name_ru
    initialValues.name_uz = props.item.name_uz
    initialValues.is_active = props.item.is_active ? 1 : 0
    initialValues.is_online = props.item.is_online ? 1 : 0
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(
          `/payment-types/${props.item.id}`,
          {
            code: values.code,
            name_en: values.name_en,
            name_ru: values.name_ru,
            name_uz: values.name_uz,
            is_active: values.is_active == 1 ? true : false,
            is_online: values.is_online == 1 ? true : false,
            type: props.item.type,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }

        setSubmitting(false)
        setLoading(false)
        return
      }
      setShow(false)
      setSubmitting(false)
      setLoading(false)
      props.refreshData()
    },
  })

  const getSelectStatusContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.is_active == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_active == value.id}
            onChange={(e) => formik.setFieldValue('is_active', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }
  const getSelectContentOnlineStatus = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2 z-0', {
            active: formik.values.is_online == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_online == value.id}
            onChange={(e) => {
              formik.setFieldValue('is_online', e.currentTarget.value)
              console.log(e.currentTarget.value)
            }}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
      >
        <Modal.Header>
          <h3 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h3>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name_ru')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                  {
                    'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                  }
                )}
                value={formik.values.name_ru}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_UZ'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name_uz')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                  {
                    'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                  }
                )}
                value={formik.values.name_uz}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_EN'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name_en')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                  {
                    'is-valid': formik.touched.name_en && !formik.errors.name_en,
                  }
                )}
                value={formik.values.name_en}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.CODE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('code')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.code && formik.errors.code},
                  {
                    'is-valid': formik.touched.code && !formik.errors.code,
                  }
                )}
                value={formik.values.code}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.AVAILABLE'})}</label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectStatusContent(MAIN_STATUSES, 'isActiveStatus-')}
                </div>
              </div>
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.ONLINE'})}</label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectContentOnlineStatus(MAIN_STATUSES, 'isOnlineStatus-')}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            type='button'
            className='btn btn-danger me-auto btn-sm'
            // @ts-ignore
            onClick={() => (handleClose(), deleteModalRef.current.showModal())}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button> */}
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>{' '}
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
