// @ts-nocheck
import axios from 'axios'
import React, {FC} from 'react'
import * as Yup from 'yup'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'
import FooterCopyright from '../../../modules/FooterCopyright'

const RolesItemsIndex: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  const navigate = useNavigate()

  let {role_id} = useParams()

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [search, setSearch] = React.useState<string>('')

  const [role, setRole] = React.useState({})
  const [modules, setModules] = React.useState<any>([])
  const [modulesItems, setModulesItems] = React.useState([])
  const [roleModuleItems, setRoleModulesItems] = React.useState([])
  const [modulesItemsTree, setModulesItemsTree] = React.useState([])
  const [searchedModulesItemsTree, setSearchedModulesItemsTree] = React.useState([])

  async function getData() {
    try {
      let roleRes = await axios.get(`/roles/${role_id}`)
      if (!roleRes?.data?.id) return navigate('/roles')
      setRole(roleRes.data || {})

      let modulesRes = await axios.get('/modules/all/?is_page_all=true')
      setModules(modulesRes.data.modules || [])

      let modulesItemsRes = await axios.get('/module-items/?is_page_all=true')
      setModulesItems(modulesItemsRes.data.module_items || [])

      let roleModuleItemsRes = await axios.get(`/roles/item/${role_id}`)
      setRoleModulesItems(roleModuleItemsRes.data || [])
      setIsLoading(false)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setIsLoading(false)
    }
  }
  React.useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  React.useEffect(() => {
    let treeModulesItems = []
    for (const module of modules || []) {
      let moduleItems = [...(modulesItems || [])].filter((x) => x?.module.id == module.id)
      let treeModuleItems = []

      for (const item of moduleItems) {
        let roleItem = (roleModuleItems || []).find((x) => x.module_item_key == item.key)

        let treeModuleItem = {
          ...item,
          isActive: !!roleItem,
        }

        treeModuleItems.push(treeModuleItem)
      }

      let treeModule = {
        ...module,
        items: treeModuleItems,
      }
      treeModulesItems.push(treeModule)
    }

    setModulesItemsTree(treeModulesItems)
  }, [modules, modulesItems, roleModuleItems])

  // HELPER FUNCTIONS
  const refreshData = async () => {
    await getData()
  }

  const getModuleItemRows = (arr: any, moduleIdx: any) => {
    if (!arr || !arr.length) return []
    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr key={'moduleItem-' + value.id}>
          <td className='align-middle'>{i + 1}</td>
          <td className='align-middle'>{value.name}</td>
          <td className='align-middle'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input c-pointer'
                type='checkbox'
                onChange={(e) => {
                  let nextModulesItemsTree = [...modulesItemsTree]
                  nextModulesItemsTree[moduleIdx].items[i].isActive =
                    !nextModulesItemsTree[moduleIdx].items[i].isActive

                  setModulesItemsTree(nextModulesItemsTree)
                }}
                checked={value.isActive}
              />
            </div>
          </td>
        </tr>
      )
    }

    return content
  }

  const getModulesTables = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <div className='mb-6 col-md-4' key={'module-' + value.id}>
          <table className='table table-sm table-striped border table-rounded table-hover gy-2 gs-7'>
            <thead>
              <tr className=' fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '20px'}}>#</th>
                <th>{value.name}</th>
                <th style={{width: '30px'}}>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input c-pointer'
                      type='checkbox'
                      onChange={(e) => {
                        let nextModulesItemsTree = [...modulesItemsTree]

                        if (e.target.checked == true) {
                          nextModulesItemsTree[i].items = nextModulesItemsTree[i].items.map(
                            (x) => ((x.isActive = true), x)
                          )
                        } else {
                          nextModulesItemsTree[i].items = nextModulesItemsTree[i].items.map(
                            (x) => ((x.isActive = false), x)
                          )
                        }
                        setModulesItemsTree(nextModulesItemsTree)
                      }}
                      checked={!value.items.find((x) => x.isActive == false)}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>{getModuleItemRows(value.items, i)}</tbody>
          </table>
        </div>
      )
    }

    return content
  }

  // FORMIK
  const validationSchema = Yup.object()
  const initialValues = {}

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)

      let user = authHelper.getAuth()
      if (!user) return

      let queryModuleItems = []
      for (let module of modulesItemsTree) {
        for (let item of module.items) {
          if (item.isActive == true) queryModuleItems.push(item.key)
        }
      }

      try {
        const res = await axios.put(
          '/roles/item/list',
          {
            role_id: parseInt(role_id, 10),
            module_item_keys: queryModuleItems,
          },
          {
            headers: {
              'Content-Type': 'applications/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setSubmitting(false)
        setLoading(false)
        return
      }

      setSubmitting(false)
      setLoading(false)

      navigate(`/roles`)
    },
  })

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.ROLES'}),
        path: '/roles',
        isSeparator: false,
        isActive: false,
      },
      {
        title: role.title,
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const filterRoles = (e) => {
    setSearch(e)

    let newRoleList = []
    modulesItemsTree.map((role: any) => {
      if (role.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())) {
        newRoleList.push(role)
        return role
      }
    })
    setSearchedModulesItemsTree(newRoleList)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{role.title}</PageTitle>
      <div className='card card-stretch shadow mb-5'>
        <div className='card-header'>
          <h2 className='card-title'>{role.title}</h2>
        </div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-4'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                value={search}
                onChange={(e) => filterRoles(e.target.value)}
              />

              {search.length > 0 && (
                <button
                  className='btn link btn-sm p-0 ms-3'
                  data-bs-toggle='modal'
                  onClick={(e) => {
                    filterRoles('')
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen040.svg'
                    className='svg-icon-2hx svg-icon-danger'
                  />
                </button>
              )}
            </div>
            <div className='d-flex justify-content-end align-items-center'>
              <button
                className='btn btn-link'
                onClick={() => {
                  let nextModulesItemsTree = [...modulesItemsTree]

                  nextModulesItemsTree = nextModulesItemsTree.map((x) => {
                    x.items = x.items.map((x) => ((x.isActive = true), x))
                    return x
                  })

                  setModulesItemsTree(nextModulesItemsTree)
                }}
              >
                {intl.formatMessage({id: 'COMMON.SELECT_ALL'})}
              </button>
              <span className='mx-3'>|</span>
              <button
                className='btn btn-link'
                onClick={() => {
                  let nextModulesItemsTree = [...modulesItemsTree]

                  nextModulesItemsTree = nextModulesItemsTree.map((x) => {
                    x.items = x.items.map((x) => ((x.isActive = false), x))
                    return x
                  })

                  setModulesItemsTree(nextModulesItemsTree)
                }}
              >
                {intl.formatMessage({id: 'COMMON.CLEAR_SELECTED'})}
              </button>
            </div>
          </div>
          <div className='row'>
            {getModulesTables(
              searchedModulesItemsTree.length > 0 || search.length > 0
                ? searchedModulesItemsTree
                : modulesItemsTree
            )}
          </div>

          <div className='row'>
            <div className='col-md-3 offset-md-9'>
              <div className='col-md-12 mb-5 mt-5'>
                <button
                  type='button'
                  className='btn btn-success w-100 mt-5'
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  disabled={loading}
                  data-kt-indicator={loading == true ? 'on' : 'off'}
                >
                  {intl.formatMessage({id: 'COMMON.SAVE'})}
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
        <div
          className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

          {<FooterCopyright />}
        </div>
      </div>
    </>
  )
}

export {RolesItemsIndex}
