/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  login: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Логин обязателен'),
  password: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Пароль обязателен'),
})

const initialValues = {
  login: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.login, values.password)
        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.access_token)
        // setCurrentUser(user)
        setCurrentUser(auth)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Пароль или логин не правильный')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      {/* <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({id: 'COMMON.LOGIN_TO'}, {name: process.env.REACT_APP_BRAND_NAME})}
        </h1>
        <div className='text-gray-400  fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary er'>
            Create an Account
          </Link>
        </div>
      </div> */}
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 er text-dark'>
          {intl.formatMessage({id: 'COMMON.LOGIN'})}
        </label>
        <input
          // placeholder='login'
          {...formik.getFieldProps('login')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.login && formik.errors.login},
            {
              'is-valid': formik.touched.login && !formik.errors.login,
            }
          )}
          type='login'
          name='login'
          autoComplete='off'
        />
        {formik.touched.login && formik.errors.login && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.login}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label er text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'COMMON.PASSWORD'})}
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 er'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary w-100 mb-6'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'COMMON.SIGNIN'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='text-muted'>
          <i className='fa-light fa-copyright'></i> {new Date().getFullYear()}{' '}
          <a href='https://exord.uz/' target='_blank'>
            Exord
          </a>{' '}
          <span>Гарантия вашего успеха.</span>
        </div>

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase er mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link
        // <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
        //   <img
        //     alt='Logo'
        //     src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
        //     className='h-20px me-3'
        //   />
        //   Continue with Facebook
        // </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
