import axios from 'axios'
import moment from 'moment'
import React, {FC, useRef} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {convertToPrice} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
// @ts-ignore
import Loader from '../../components/Loader'
import FooterCopyright from '../../modules/FooterCopyright'

const StatisticsAbcXyzIndex: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let dateFrom = searchParams.get('dateFrom') || moment().format('YYYY-MM-DD')
  let dateTo = searchParams.get('dateTo') || moment().format('YYYY-MM-DD')

  const [data, setData] = React.useState<any>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const getQueryObject = (): any => {
    let obj: {
      dateFrom?: string
      dateTo?: string
    } = {}

    if (!!dateFrom) obj.dateFrom = dateFrom
    if (!!dateTo) obj.dateTo = dateTo

    return obj
  }

  const buildQueryParams = () => {
    let query = `?`

    if (!!dateFrom) query += `start_date=${dateFrom}`
    if (!!dateTo) query += `&end_date=${dateTo}`

    return query
  }

  async function getData() {
    try {
      const res = await axios.get(`/stats/products/abc-xyz/${buildQueryParams()}`)
      setData(res.data || [])

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      }
      if (err.response.status == 500) {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  React.useEffect(() => {
    setData([])
    getData()
  }, [searchParams])

  const handleDateRangePickerCallback = (start: any, end: any, label: any) => {
    setSearchParams({
      ...getQueryObject(),
      dateFrom: start.format('YYYY-MM-DD'),
      dateTo: end.format('YYYY-MM-DD'),
    })
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.STATS'}),
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'COMMON.CURRENT'}),
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  // const exportToExcel = () => {
  //   let newData: any = []
  //   data.map((d: any) => {
  //     newData.push({
  //       Тип:
  //         d.type == 'completed'
  //           ? intl.formatMessage({id: 'COMMON.COMPLETED'})
  //           : intl.formatMessage({id: 'COMMON.UNCOMPLETED'}),
  //       Количество: d.count,
  //       Сред: d.avg,
  //       Итого: d.total,
  //     })
  //   })
  //   newData.push({
  //     Итого: convertToPrice(total),
  //   })

  //   const fileType =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   const fileExtension = '.xlsx'
  //   const fileName = 'Выручка'

  //   const ws = XLSX.utils.json_to_sheet(newData)
  //   const wb = {Sheets: {data: ws}, SheetNames: ['data']}
  //   const excelBuffer = XLSX.write(wb, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   })
  //   const dataBlob = new Blob([excelBuffer], {type: fileType})
  //   const url = URL.createObjectURL(dataBlob)
  //   const link = document.createElement('a')
  //   link.href = url
  //   link.download = fileName + fileExtension
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  const getDataByCategory = (arr: any, category: any) => {
    if (!arr.length || !category) {
      return {
        count: 0,
        percentage: 0,
      }
    }

    let curr = arr.find((x: any) => x.abcXyzCategory == category)
    if (!curr) {
      return {
        count: 0,
        percentage: 0,
      }
    }

    return curr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.ABCXYZ'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='input-group-sm cs-daterangepicker-wrapper'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(dateFrom, 'YYYY-MM-DD'),
                    endDate: moment(dateTo, 'YYYY-MM-DD'),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>
            </div>
            {/* <div className='card-toolbar'>
              <div className='ms-3'>
                <button
                  className='btn btn-success'
                  data-bs-toggle='modal'
                  onClick={exportToExcel}
                >
                  {intl.formatMessage({id: 'COMMON.DOWNLOAD'})}
                </button>
              </div>
            </div> */}
          </div>
        </div>

        <div
          className='card card-stretch shadow mb-2 flex-column-fluid main-overflow-x'
          style={{height: windowSize.current[1] - 320}}
        >
          <div className='card-body'>
            <div className='row'>
              <div className='col-3'>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center h-100px'></div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1'>A</span>
                    <span>Высокая прибыль</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1'>B</span>
                    <span>Средняя прибыль</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1'>C</span>
                    <span>Низкая прибыль</span>
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1'>X</span>
                    <span>Высокая стабильность</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'AX').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'AX').percentage)} %</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'BX').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'BX').percentage)} %</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'CX').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'CX').percentage)} %</span>
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1'>Y</span>
                    <span>Средняя стабильность</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'AY').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'AY').percentage)} %</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'BY').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'BY').percentage)} %</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'CY').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'CY').percentage)} %</span>
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1'>Z</span>
                    <span>Низкая стабильность</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'AZ').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'AZ').percentage)} %</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'BZ').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'BZ').percentage)} %</span>
                  </div>
                </div>
                <div className='p-2'>
                  <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                    <span className='fs-1  text-primary'>
                      {convertToPrice(getDataByCategory(data, 'CZ').count)}
                    </span>
                    <span>{convertToPrice(getDataByCategory(data, 'CZ').percentage)} %</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}

export {StatisticsAbcXyzIndex}
