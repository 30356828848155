import 'bootstrap-daterangepicker/daterangepicker.css'
import React, {useEffect} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'

import axios from 'axios'
import moment from 'moment'

// @ts-ignore:
import Select from 'react-select'
import Timer from '../../components/Timer/Timer'

import ReactPaginate from 'react-paginate'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'

// HELPERS
import clsx from 'clsx'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import Loader from '../../components/Loader'
import {useRoleContext} from '../../context/getAccessRoleUser'
import {SYSTEMS} from '../../utils/constants'
import {dateTimeFormat, getPriceFormatted} from '../../utils/helpers'

export function OrdersIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''
  let dateFrom = searchParams.get('dateFrom') || moment().format('YYYY-MM-DD')
  let dateTo = searchParams.get('dateTo') || moment().format('YYYY-MM-DD')
  let status = searchParams.get('status') || ''
  let terminal_id = searchParams.get('terminal_id') || ''

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [statuses, setStatuses] = React.useState<any>([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [branches, setBranches] = React.useState<any>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })
  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
      dateFrom?: string
      dateTo?: string
      status?: any
      terminal_id?: any
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search
    if (!!dateTo) obj.dateTo = dateTo
    if (!!dateFrom) obj.dateFrom = dateFrom
    if (!!status) obj.status = status
    if (!!terminal_id) obj.terminal_id = terminal_id

    return obj
  }

  const buildQueryParams = () => {
    let query = `?page=${page}&page_size=${page_size}`

    if (!!dateFrom) query += `&from=${dateFrom}`
    if (!!dateTo) query += `&to=${dateTo}`
    if (!!multi_search) query += `&multi_search=${multi_search}`
    if (!!status) query += `&status=${status}`
    if (!!terminal_id) query += `&terminal_id=${terminal_id}`

    return query
  }

  async function getData() {
    try {
      const res = await axios.get(`orders/${buildQueryParams()}`)
      setData(res.data.data || [])
      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getOrderStatusLog = (orderLogs: any = [], status: string) => {
    let ordersLog = orderLogs.map((x: any) => x)
    let filteredOrdersLog = ordersLog.filter((orderLog: any) => orderLog.event == status)
    return filteredOrdersLog[filteredOrdersLog.length - 1]
  }
  function calculateTimeDifference(order: any, start: string, end: string) {
    let filterStatusLogStart = order.order_logs.filter((orderLog: any) => orderLog.event == start)
    let filterStatusLogEnd = order.order_logs.filter((orderLog: any) => orderLog.event == end)

    const startTime = new Date(
      filterStatusLogStart[filterStatusLogStart.length - 1]?.date
    ).getTime()
    const endTime = new Date(filterStatusLogEnd[filterStatusLogEnd.length - 1]?.date).getTime()
    const difference = endTime - startTime

    if (difference < 0) {
      return <>00:00:00</>
    }
    // Convert difference to minutes and seconds
    const seconds =
      Math.floor((difference / 1000) % 60) > 9
        ? Math.floor((difference / 1000) % 60)
        : '0' + Math.floor((difference / 1000) % 60)
    const minutes =
      Math.floor((difference / (1000 * 60)) % 60) > 9
        ? Math.floor((difference / (1000 * 60)) % 60)
        : '0' + Math.floor((difference / (1000 * 60)) % 60)
    const hours =
      Math.floor((difference / (1000 * 60 * 60)) % 24) > 9
        ? Math.floor((difference / (1000 * 60 * 60)) % 24)
        : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    return (
      <>
        {hours}:{minutes}:{seconds}
      </>
    )
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className={clsx(
            'c-pointer bg-opacity-25',
            {'bg-danger': value.status === 9},
            {
              'bg-success': value.status === 8,
            },
            {
              'bg-warning': value.status < 8 && value.status > 0,
            }
          )}
          onClick={() => {
            if (!findRoleUser('orders-edit')) return
            if (!!value.has_child) {
              setSearchParams({parent_id: value.id})
              return
            }
            navigate(`/orders/edit-order?orderId=${value.id}`)
            return
          }}
        >
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input widget-9-check'
                type='checkbox'
                value='1'
                checked={selectedItems.includes(value.id)}
                onClick={(e) => {
                  e.stopPropagation()
                  changeSelectedItems([value])
                }}
              />
            </div>
          </td>
          <td>
            {value.is_self_service ? (
              <i className='fa fa-user text-primary'></i>
            ) : (
              <i className='fa fa-car text-danger'></i>
            )}
          </td>
          <td>{value.id}</td>
          <td>{value.number}</td>
          <td>{(value.terminal || {}).name_ru}</td>
          <td className='text-center'>
            {value.status < 9 ? (
              <span className='btn btn-outline btn-color-muted px-2 py-1 active btn-active-light-success w-100'>
                {(!!getOrderStatusLog(value.order_logs || [], 'orderCreate') && (
                  <>
                    {value.status < 7 && (
                      <Timer
                        targetTime={
                          getOrderStatusLog(
                            [{date: value?.created_at, event: 'orderCreate'}] || [],
                            'orderCreate'
                          )?.date
                        }
                      />
                    )}
                    {(value.status === 7 || value.status === 8) &&
                      (!!getOrderStatusLog(value.order_logs || [], 'Delivered')
                        ? calculateTimeDifference(value, 'WaitCooking', 'Delivered')
                        : calculateTimeDifference(value, 'WaitCooking', 'Closed'))}
                  </>
                )) ||
                  '-'}
              </span>
            ) : (
              '-'
            )}
          </td>
          <td>{value.name}</td>
          <td>{value.phone}</td>
          <td className='text-center'>{dateTimeFormat(value.created_at)}</td>
          <td className='text-center'>{(value.payment || {}).name_ru || '-'}</td>
          <td className='text-nowrap text-end'>{getPriceFormatted(parseInt(value.full_sum))}</td>
          <td className='text-center'>{(value.statuses || {}).description_ru}</td>
          <td>{value.courier?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td className='text-center'>{SYSTEMS[value.system]}</td>
          <td>{value.created?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
        </tr>
      )
    }

    return content
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const filteredStatuses = (array: any) => {
    let list: {
      value: string
      label: string
    }[] = []
    array.map((ar: any) => list.push({value: ar.id, label: ar.description_ru}))
    return list
  }
  const filteredBranches = (array: any) => {
    let list: {
      value: string
      label: string
    }[] = []
    array.map((ar: any) => list.push({value: ar.id, label: ar.name_ru}))
    return list
  }

  const handleDateRangePickerCallback = (start: any, end: any, label: any) => {
    setSearchParams({
      ...getQueryObject(),
      dateFrom: start.format('YYYY-MM-DD'),
      dateTo: end.format('YYYY-MM-DD'),
    })
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  async function cancellOrder() {
    try {
      const res = await axios.patch(
        '/orders/cancel',
        {
          cancel_reason: 'Cancell all',
          order_ids: [...selectedItems],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CHANGE'}), 'success')
      )
      getData()
    } catch (err: any) {
      if (err.response.status == 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }
  }

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems

    setData([...data])

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem == item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem != item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  const clearSearchParams = () => {
    searchParams.delete('status')
    searchParams.delete('terminal_id')
    setSearchParams(searchParams)
  }

  if (isLoading) return <Loader />
  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.ORDERS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {findRoleUser('orders-add') && (
                <button
                  className='btn btn-success me-3'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => navigate(`/orders/create-order`)}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )}

              <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={multi_search}
                  onChange={(e) =>
                    setSearchParams({...getQueryObject(), multi_search: e.target.value})
                  }
                />

                {multi_search.length > 0 && (
                  <button
                    className='btn link btn-sm'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                  >
                    <i className='fa fa-times fs-5'></i>
                  </button>
                )}
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='me-3 input-group-sm position-relative es-datepicker-wrapper'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(),
                    endDate: moment(),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>
              <div className='dropdown'>
                <button
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary me-3'
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa-solid fa-bars'></i>
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <button className='btn dropdown-item'>
                      {intl.formatMessage({id: 'COMMON.SUBMIT'})}
                    </button>
                  </li>
                  <li>
                    <button className='btn dropdown-item' onClick={cancellOrder}>
                      {intl.formatMessage({id: 'COMMON.CANCEL'})}
                    </button>
                  </li>
                </ul>
              </div>
              <div className='ms-3'>
                <button
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-funnel fs-5'></i>
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                >
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark er'>
                      {intl.formatMessage({id: 'COMMON.FILTER'})}
                    </div>
                  </div>

                  <div className='separator border-gray-200'></div>

                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <div className='mb-5'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.STATUS'})}
                        </label>
                        <Select
                          classNamePrefix='react-select'
                          styles={{
                            menu: (provided) => ({...provided, zIndex: 5}),
                          }}
                          placeholder=''
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          className='react-select-styled react-select-solid'
                          options={filteredStatuses(statuses)}
                          onChange={(e: any) => {
                            setSearchParams({...getQueryObject(), status: e.value})
                          }}
                          value={{
                            value: (statuses.find((x: any) => x.id == status) || {}).code || null,
                            label:
                              (statuses.find((x: any) => x.id == status) || {}).description_ru ||
                              '',
                          }}
                        />
                      </div>
                      <div className='mb-5'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'MENU.BRANCHES'})}
                        </label>
                        <Select
                          classNamePrefix='react-select'
                          styles={{
                            menu: (provided) => ({...provided, zIndex: 5}),
                          }}
                          placeholder=''
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          className='react-select-styled react-select-solid'
                          options={filteredBranches(branches)}
                          onChange={(e: any) => {
                            setSearchParams({...getQueryObject(), terminal_id: e.value})
                          }}
                          value={{
                            value:
                              (branches.find((x: any) => x.id == terminal_id) || {}).id || null,
                            label:
                              (branches.find((x: any) => x.id == terminal_id) || {}).name_ru || '',
                          }}
                        />
                      </div>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={clearSearchParams}
                      >
                        {intl.formatMessage({id: 'COMMON.CLEAR'})}
                      </button>

                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        {intl.formatMessage({id: 'COMMON.CLOSE'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <i className='fa-solid fa-filter'></i>
                Filter
              </button> */}
            </div>
          </div>
        </div>

        {/* <div className='card card-stretch shadow mb-5'>
          <div className='card-header'>
            <h2 className='card-title'>
              {
                // @ts-ignore
                region.name_ru
              }
            </h2>
          </div>
        </div> */}

        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          {/* <div className='card-header'>
            <div className='card-title'>
              <div className='d-flex align-items-center position-relative input-group-sm my-1'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={multi_search}
                  onChange={(e) =>
                    setSearchParams({...getQueryObject(), multi_search: e.target.value})
                  }
                />

                {multi_search.length > 0 && (
                  <button
                    className='btn link btn-sm p-0 ms-3'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-2hx svg-icon-danger'
                    />
                  </button>
                )}
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='me-3 input-group-sm position-relative'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(),
                    endDate: moment(),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>

              <button
                className='btn btn-primary btn-sm'
                data-bs-toggle='modal' // @ts-ignore
                onClick={() => navigate(`/orders/create-order`)}
              >
                {intl.formatMessage({id: 'COMMON.ADD'})}
              </button>
            </div>
          </div> */}
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              {/* <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={data.length <= selectedItems.length}
                          onChange={(e) => {
                            changeSelectedItems(data, true)
                          }}
                        />
                      </div>
                    </th>
                    <th>{intl.formatMessage({id: 'COMMON.ORDER_NUMBER'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.BRANCH'})}</th>
                    <th className='min-w-10px'>
                      {intl.formatMessage({id: 'COMMON.CHEQUE_NUMBER'})}
                    </th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.CLIENT'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                    <th className='min-w-50px'>
                      {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                    </th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.SYSTEM'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table> */}
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={data.length <= selectedItems.length}
                          onChange={(e) => {
                            changeSelectedItems(data, true)
                          }}
                        />
                      </div>
                    </th>
                    <th className='w-20px'></th>
                    <th>{intl.formatMessage({id: 'COMMON.ORDER_NUMBER'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.CHEQUE_NUMBER'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.BRANCH'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.TIMER'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.CLIENT'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                    </th>
                    <th className='text-nowrap text-end'>
                      {intl.formatMessage({id: 'COMMON.TOTAL'})}
                    </th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.COURIER'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.SYSTEM'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.CREATED'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={3}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                  marginPagesDisplayed={0}
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
