import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {CustomLayout} from '../../_metronic/layout/CustomLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {AccountsIndex} from '../pages/accounts/Index'
import {AccountViewIndex} from '../pages/accounts/view/Index'
import {BranchesIndex} from '../pages/branches/Index'
import {FeedbacksIndex} from '../pages/feedbacks/Index'
import {GalleryIndex} from '../pages/gallery/Index'
import {GroupsIndex} from '../pages/groups/Index'
import {GroupsMenuIndex} from '../pages/groups/Menu'
import {GroupsProductsIndex} from '../pages/groups/products/index'
import {GroupsProductsItemIndex} from '../pages/groups/products/item/Index'
import {HeatMapIndex} from '../pages/heat-map/Index'
import {NewsIndex} from '../pages/news/Index'
import {NotificationsIndex} from '../pages/notifications/Index'
import {CreateOrder} from '../pages/orders/CreateOrder'
import {OrdersIndex} from '../pages/orders/Index'
import {OrdersView} from '../pages/orders/View'
import {OrganizationsIndex} from '../pages/organizations/Index'
import {PagesIndex} from '../pages/pages/Index'
import {RestaurantsIndex} from '../pages/restaurants/Index'
import {RolesIndex} from '../pages/roles/Index'
import {RolesItemsIndex} from '../pages/roles/items/Index'
import {SettingsIndex} from '../pages/settings/Index'
import {SliderIndex} from '../pages/slider/Index'
import {StatisticsAbcIndex} from '../pages/statistics/Abc'
import {StatisticsAccountsIndex} from '../pages/statistics/Accounts'
import {StatisticsCouriersIndex} from '../pages/statistics/Couriers'
import {StatisticsIndex} from '../pages/statistics/Index'
import {StatisticsLocationIndex} from '../pages/statistics/Location'
import {StatisticsOrderTypeIndex} from '../pages/statistics/OrderType'
import {StatisticsPaymentTypeIndex} from '../pages/statistics/PaymentType'
import {StatisticsProductIndex} from '../pages/statistics/Product'
import {StatisticsSystemIndex} from '../pages/statistics/System'
import {StatisticsTerminalsIndex} from '../pages/statistics/Terminals'
import {StatisticsUsersIndex} from '../pages/statistics/Users'
import {UsersIndex} from '../pages/users/Index'

import {CouriersIndex} from '../pages/couriers'
import {CouriersMapIndex} from '../pages/couriers-map/Index'
import {CouriersItemIndex} from '../pages/couriers/view'
import {EditOrder} from '../pages/orders/EditOrder'
import {PaymentTypes} from '../pages/payment-types'
import ProfileIndex from '../pages/profile'
import {StatisticsAbcXyzIndex} from '../pages/statistics/Abcxyz'
import {StatisticsRfmIndex} from '../pages/statistics/Rfm'
import {StatisticsXyzIndex} from '../pages/statistics/Xyz'

import {AccountViewTestIndex} from '../pages/accounts/view/Index copy'
import {GroupsProductsTestItemIndex} from '../pages/groups/products/item/Index copy'
import {RedirectCalls} from '../pages/redirect/Calls'
import {RolesItemsTestIndex} from '../pages/roles/items/Index copy'
import {StatisticsAbcClienntsIndex} from '../pages/statistics/Abc_clients'
import {UserItemIndex} from '../pages/users/view'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<CustomLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='statistics' element={<StatisticsIndex />} />
        <Route path='statistics/accounts' element={<StatisticsAccountsIndex />} />
        <Route path='statistics/location' element={<StatisticsLocationIndex />} />
        <Route path='statistics/users' element={<StatisticsUsersIndex />} />
        <Route path='statistics/order-type' element={<StatisticsOrderTypeIndex />} />
        <Route path='statistics/payment-type' element={<StatisticsPaymentTypeIndex />} />
        <Route path='statistics/products' element={<StatisticsProductIndex />} />
        <Route path='statistics/system' element={<StatisticsSystemIndex />} />
        <Route path='statistics/terminals' element={<StatisticsTerminalsIndex />} />
        <Route path='statistics/couriers' element={<StatisticsCouriersIndex />} />
        <Route path='statistics/abc' element={<StatisticsAbcIndex />} />
        <Route path='statistics/abc-clients' element={<StatisticsAbcClienntsIndex />} />
        <Route path='statistics/xyz' element={<StatisticsXyzIndex />} />
        <Route path='statistics/abcxyz' element={<StatisticsAbcXyzIndex />} />
        <Route path='statistics/rfm' element={<StatisticsRfmIndex />} />

        {/* <Route path='statistics/cashbox' element={<StatisticsCashboxIndex />} />
        <Route path='statistics/doctors' element={<StatisticsDoctorsIndex />} />
        <Route path='statistics/assistents' element={<StatisticsAssistentsIndex />} />
        <Route path='statistics/referral-doctors' element={<StatisticsReferralDoctorsIndex />} />
        <Route path='statistics/insurance' element={<StatisticsInsuranceIndex />} /> */}
        <Route path='profile' element={<ProfileIndex />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='/settings' element={<SettingsIndex />} />
        <Route path='/users' element={<UsersIndex />} />
        <Route path='/users/:id' element={<UserItemIndex />} />
        <Route path='/branches' element={<BranchesIndex />} />
        <Route path='/restaurants' element={<RestaurantsIndex />} />
        <Route path='/pages' element={<PagesIndex />} />
        <Route path='/news' element={<NewsIndex />} />
        <Route path='/gallery' element={<GalleryIndex />} />
        <Route path='/slider' element={<SliderIndex />} />
        <Route path='/payment-types' element={<PaymentTypes />} />
        <Route path='/notifications' element={<NotificationsIndex />} />
        <Route path='/accounts' element={<AccountsIndex />} />
        <Route path='/account/:id' element={<AccountViewIndex />} />
        <Route path='/account/test/:id' element={<AccountViewTestIndex />} />
        <Route path='/feedbacks' element={<FeedbacksIndex />} />
        <Route path='/orders' element={<OrdersIndex />} />
        <Route path='/orders/view/:id' element={<OrdersView />} />
        <Route path='/orders/create-order' element={<CreateOrder />} />
        <Route path='/orders/edit-order' element={<EditOrder />} />
        <Route path='/couriers-map' element={<CouriersMapIndex />} />
        <Route path='/couriers' element={<CouriersIndex />} />
        <Route path='/couriers/:id' element={<CouriersItemIndex />} />
        <Route path='/organizations' element={<OrganizationsIndex />} />
        <Route path='/groups' element={<GroupsIndex />} />
        <Route path='/groups/menu/:org_id' element={<GroupsMenuIndex />} />
        <Route
          path='/groups/menu/:org_id/products/:parent_group'
          element={<GroupsProductsIndex />}
        />
        <Route
          path='/groups/menu/:org_id/products/:parent_group/:product_id'
          element={<GroupsProductsItemIndex />}
        />
        <Route
          path='/groups/menu/:org_id/products/test/:parent_group/:product_id'
          element={<GroupsProductsTestItemIndex />}
        />

        <Route path='/heat-map' element={<HeatMapIndex />} />
        {/* Roles Page */}
        <Route path='roles' element={<RolesIndex />} />
        <Route path='roles/:role_id/items' element={<RolesItemsIndex />} />
        <Route path='roles/:role_id/items/test' element={<RolesItemsTestIndex />} />
        <Route path='/calls/new' element={<RedirectCalls />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
