import axios from 'axios'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'

import {useFormik} from 'formik'
import * as authHelper from '../../../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {swal} from '../../../../../../_metronic/helpers/swal'

import {swalDefaultConfig} from '../../../../../../_metronic/helpers/constants'
import {convertToPrice, getMediaUrl} from '../../../../../../_metronic/helpers/general'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [data, setData] = React.useState<any>(props.products)
  const [filteredData, setFilteredDataData] = React.useState<any>()
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems

    setData([...data])

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem == item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem != item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  useEffect(() => {
    setData(props.products || [])
  }, [props.products])

  useEffect(() => {
    changeSelectedItems(props?.recommend || [])
  }, [props.recommend])

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr key={'list-' + value.id} className='c-pointer'>
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input widget-9-check'
                type='checkbox'
                value='1'
                checked={selectedItems.includes(value.id)}
                onClick={(e) => {
                  e.stopPropagation()
                  changeSelectedItems([value])
                }}
              />
            </div>
          </td>
          <td>{i + 1}</td>
          <td>
            <img className='row-img' src={getMediaUrl('products', value.image)} alt='' />
          </td>
          <td>{value.name_ru}</td>
          <td>{value.url}</td>
          <td className='text-nowrap'>
            {convertToPrice(value?.price || 0) + ' ' + intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
        </tr>
      )
    }

    return content
  }

  let modalRef = useRef(null)

  const initialValues = {}

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.patch(
          `/products/recommend/${props.id}`,
          {
            ids: selectedItems,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
        setSelectedItems([])
        setLoading(false)
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setSelectedItems([])
        setLoading(false)
        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  function filterByName(name: React.ChangeEvent<HTMLInputElement>) {
    setFilteredDataData([])
    data.map((d: any) => {
      if (d.name_ru.toLocaleLowerCase().includes(name.target.value)) {
        setFilteredDataData((prev: any) => [...prev, d])
      }
    })
    if (name.target.value.length == 0) {
      setFilteredDataData([])
    }
    setSearch(name.target.value)
  }

  return (
    <>
      <Modal
        enforceFocus={false}
        size='xl'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'MENU.PRODUCTS'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div className='card-body'>
                <div className='d-flex align-items-center position-relative input-group-sm mb-3'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-1 position-absolute ms-4'
                  />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                    value={search}
                    onChange={filterByName}
                  />

                  {search.length > 0 && (
                    <button
                      className='btn link btn-sm p-0 ms-3'
                      data-bs-toggle='modal'
                      onClick={(e) => {
                        setSearch('')
                        setFilteredDataData([])
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-2hx svg-icon-danger'
                      />
                    </button>
                  )}
                </div>

                <div className='table-responsive'>
                  <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                    <thead>
                      <tr className=' bg-light'>
                        <th className='w-25px'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              checked={data.length <= selectedItems.length}
                              onChange={(e) => {
                                changeSelectedItems(data, true)
                              }}
                            />
                          </div>
                        </th>
                        <th>#</th>
                        <th>{intl.formatMessage({id: 'COMMON.IMAGE'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.URL'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {search.length > 0 ? getDataContent(filteredData) : getDataContent(data)}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
})
