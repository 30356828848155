import clsx from 'clsx'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore:
import Dropzone from 'react-dropzone'

import {useFormik} from 'formik'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {UseMutationResult} from 'react-query'
import {FormErrorComponent} from '../../../components/FormErrorComponent'

const ItemModal: React.FC<{
  item: {
    id: number
    title: string
    key: string
    value: string
    file: string
  }
  isOpen: boolean
  onClose: () => void
  updateMutation: UseMutationResult
  deleteMutation: UseMutationResult
}> = ({item, isOpen, onClose, updateMutation, deleteMutation}) => {
  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    key: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    value: Yup.string().when('file', {
      // @ts-ignore
      is: (file) => !file || !(file || {}).name,
      then: Yup.string()
        .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    }),
    file: Yup.mixed(),
  })

  const initialValues = {
    title: item?.title || '',
    key: item?.key || '',
    value: item?.value || '',
    file: item?.file || '',
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus}) => {
      const row = {
        id: item?.id,
        key: values.key,
        title: values.title,
        value: values.value,
        file: values.file || null,
      }
      updateMutation.mutate(row, {
        onSuccess: handleClose,
        onError: (error: any) => {
          setStatus(error?.response?.data?.message || 'An unexpected error occurred')
          console.error('Error on mutation', error?.response?.data?.message)
        },
      })
    },
  })

  return (
    <>
      <Modal className='es-modal' size='lg' show={isOpen} onHide={handleClose} centered>
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <FormErrorComponent error={formik.status} />

            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('title')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.title && formik.errors.title},
                  {
                    'is-valid': formik.touched.title && !formik.errors.title,
                  }
                )}
                value={formik.values.title}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                  <div data-validator='notEmpty'>{formik.errors.title}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.KEY'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('key')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.key && formik.errors.key},
                  {
                    'is-valid': formik.touched.key && !formik.errors.key,
                  }
                )}
                value={formik.values.key}
              />
              {formik.touched.key && formik.errors.key ? (
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                  <div data-validator='notEmpty'>{formik.errors.key}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.VALUE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('value')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.value && formik.errors.value},
                  {
                    'is-valid': formik.touched.value && !formik.errors.value,
                  }
                )}
                value={formik.values.value}
              />
              {formik.touched.value && formik.errors.value ? (
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                  <div data-validator='notEmpty'>{formik.errors.value}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.IMAGE'})}</label>
              <Dropzone multiple={false} onDrop={(files) => formik.setFieldValue('file', files[0])}>
                {({getRootProps, getInputProps}) => (
                  <div
                    {...getRootProps({
                      className: clsx(
                        'form-control c-dropzone',
                        {
                          'is-invalid': formik.touched.file && formik.errors.file,
                        },
                        {
                          'is-valid': formik.touched.file && !formik.errors.file,
                        }
                      ),
                    })}
                  >
                    <input {...getInputProps()} />
                    <p className='m-0'>
                      {!!formik.values.file
                        ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED'})
                        : intl.formatMessage({id: 'COMMON.SELECT_PICTURE'})}
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-danger btn-sm me-auto'
            data-bs-toggle='modal'
            // @ts-ignore
            onClick={() => deleteModalRef.current.showModal()}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button>

          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={updateMutation.isLoading}
            data-kt-indicator={updateMutation.isLoading ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>

      {/* <DeleteModal item={item?.id} mutation={deleteMutation} /> */}
    </>
  )
}
export default ItemModal
