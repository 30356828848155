import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {Button, FullscreenControl, Map, Polygon, YMaps, ZoomControl} from '@pbe/react-yandex-maps'

export const PolygonMapModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  let mapRef = useRef<any>()
  let ymapRef = useRef<any>()
  let polygonRef = useRef<any>()

  const [defaultMapLocation, setDefaultMapLocation] = useState({
    latitude: 41.31065,
    longitude: 69.279608,
  })
  const [polygonArea, setPolygonArea] = useState<any>([])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useImperativeHandle(ref, () => ({
    showModal() {
      setPolygonArea([
        JSON.parse(props.formik.values.coordinates || '[]').map((y: any) => [y[1], y[0]]),
      ])
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({})

  const initialValues = {}

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      let coordinates = polygonRef.current.geometry.getCoordinates()
      props.formik?.setFieldValue(
        'coordinates',
        JSON.stringify((coordinates[0] || []).map((x: any) => [x[1], x[0]]) || '[]')
      )
      setShow(false)
    },
  })

  const startPolygon = () => {
    mapRef.current.geoObjects.add(polygonRef.current)
    polygonRef.current.editor.startDrawing()
  }

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.POLYGON'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <div
          style={{
            height: '600px',
            width: '100%',
          }}
        >
          <YMaps
            query={{
              apikey: process.env.REACT_APP_YMAPS_KEY,
              lang: 'ru_RU',
              load: 'package.full',
            }}
          >
            <Map
              instanceRef={(map) => {
                if (map) mapRef.current = map
              }}
              onLoad={(mapRefArg: any) => {
                if (mapRefArg) ymapRef.current = mapRefArg
              }}
              defaultState={{
                center: [defaultMapLocation.latitude, defaultMapLocation.longitude],
                zoom: 11,
                controls: [],
              }}
              options={{
                suppressMapOpenBlock: true,
              }}
              style={{width: '100%', height: '100%'}}
            >
              <Polygon
                instanceRef={(map) => {
                  if (map) polygonRef.current = map
                }}
                geometry={polygonArea}
                options={{
                  // @ts-ignore
                  fillColor: props.formik.values.polygon_color,
                  strokeColor: props.formik.values.polygon_color,
                  opacity: 0.3,
                  strokeWidth: 5,
                }}
              />

              <Button
                options={{maxWidth: 128}}
                data={{content: 'Начать'}}
                onClick={(e: any) => startPolygon()}
              />

              <FullscreenControl />
              <ZoomControl />
            </Map>
          </YMaps>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SUBMIT'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
