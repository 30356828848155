import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import React from 'react'

import {YMaps, useYMaps} from '@pbe/react-yandex-maps'
import {useIntl} from 'react-intl'
import {selectStyles} from '../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
// @ts-ignore:
import Select from 'react-select'
import Loader from '../../components/Loader'

export function HeatMapIndex() {
  const intl = useIntl()
  const {classes} = useLayout()

  const [startDate, setStartDate] = React.useState(moment().startOf('month').format())
  const [endDate, setEndDate] = React.useState(moment().format())
  const [branch, setBranch] = React.useState({value: '', label: ''})
  const [data, setData] = React.useState(null)
  const [branches, setBranches] = React.useState<any>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function getData() {
    try {
      const res = await axios.get(
        `/orders/heatmap-data/?start_date_time=${startDate
          .replace('T', ' ')
          .replace('+', '-')}&end_date_time=${endDate.replace('T', ' ').replace('+', '-')}&branch=${
          branch?.value
        }`
      )
      setData(
        // @ts-ignore:
        ((res.data || []).data || []).map((x) => [parseFloat(x.latitude), parseFloat(x.longitude)])
      )

      const branchesRes = await axios.get('/terminals/all/')
      setBranches(branchesRes.data.data)
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  React.useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  const refreshData = () => {
    setIsLoading(true)
    getData()
  }

  const HeatMap = () => {
    const mapRef = React.useRef(null)
    const ymaps = useYMaps(['Map'])

    React.useEffect(() => {
      if (!ymaps || !mapRef.current) return

      window.ymaps = ymaps

      let heatmapIns = new ymaps.Map(mapRef.current, {
        center: [41.311151, 69.279737],
        zoom: 12,
      })

      const script = document.createElement('script')
      script.src = 'https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js'
      script.async = true
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        // @ts-ignore:
        ymaps.modules.require(['Heatmap'], function (Heatmap) {
          let heatmap = new Heatmap(data, {
            // Радиус влияния.
            radius: 15,
            // Нужно ли уменьшать пиксельный размер точек при уменьшении зума. False - не нужно.
            dissipating: false,
            // Прозрачность тепловой карты.
            opacity: 0.8,
            // Прозрачность у медианной по весу точки.
            intensityOfMidpoint: 0.2,
            // JSON описание градиента.
            gradient: {
              0.1: 'rgba(128, 255, 0, 0.7)',
              0.2: 'rgba(255, 255, 0, 0.8)',
              0.7: 'rgba(234, 72, 58, 0.9)',
              1.0: 'rgba(162, 36, 25, 1)',
            },
          })
          heatmap.setMap(heatmapIns)
        })
      })
    }, [ymaps])
    return <div ref={mapRef} style={{width: '100%', height: '100%'}} />
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.HEAT_MAP'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header border-0 h-auto'>
            <form className='row w-100'>
              <div className='col-12 col-md-2 my-4'>
                <input
                  type='date'
                  value={moment(startDate).format('YYYY-MM-DD')}
                  onChange={(event) => {
                    let dataArr = event.currentTarget.value.split('-')
                    setStartDate(
                      moment(startDate)
                        .set({
                          year: parseInt(dataArr[0], 10),
                          month: parseInt(dataArr[1], 10) - 1,
                          date: parseInt(dataArr[2], 10),
                        })
                        .format()
                    )
                  }}
                  className={clsx('form-control')}
                />
              </div>
              <div className='col-12 col-md-2 my-4'>
                <input
                  type='date'
                  value={moment(endDate).format('YYYY-MM-DD')}
                  onChange={(event) => {
                    let dataArr = event.currentTarget.value.split('-')

                    setEndDate(
                      moment(endDate)
                        .set({
                          year: parseInt(dataArr[0], 10),
                          month: parseInt(dataArr[1], 10) - 1,
                          date: parseInt(dataArr[2], 10),
                        })
                        .format()
                    )
                  }}
                  className={clsx('form-control')}
                />
              </div>
              <div className='col-12 col-md-2 my-4'>
                <input
                  type='time'
                  value={moment(startDate).format('HH:mm')}
                  onChange={(event) => {
                    let timeArr = event.currentTarget.value.split(':')
                    setStartDate(
                      moment(startDate)
                        .set({
                          hour: parseInt(timeArr[0], 10),
                          minute: parseInt(timeArr[1], 10),
                        })
                        .format()
                    )
                  }}
                  className={clsx('form-control')}
                />
              </div>
              <div className='col-12 col-md-2 my-4'>
                <input
                  type='time'
                  value={moment(endDate).format('HH:mm')}
                  onChange={(event) => {
                    let timeArr = event.currentTarget.value.split(':')
                    setEndDate(
                      moment(endDate)
                        .set({
                          hour: parseInt(timeArr[0], 10),
                          minute: parseInt(timeArr[1], 10),
                        })
                        .format()
                    )
                  }}
                  className={clsx('form-control')}
                />
              </div>
              <div className='col-12 col-md-2 my-4'>
                <Select
                  classNamePrefix='react-select'
                  placeholder=''
                  components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                  className='react-select-styled react-select-solid'
                  options={branches?.map((x: any) => ({
                    value: x.id,
                    label: x.name_ru,
                  }))}
                  onChange={(e: any) => setBranch(e)}
                  value={branch}
                  styles={selectStyles}
                />
              </div>
              <div className='col-12 col-md-2 my-4'>
                <button
                  className='btn btn-success'
                  onClick={(e) => {
                    e.preventDefault()
                    refreshData()
                  }}
                >
                  {intl.formatMessage({id: 'COMMON.APPLY'})}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body p-0'>
            <YMaps
              query={{
                apikey: process.env.REACT_APP_YMAPS_KEY,
                lang: 'ru_RU',
                load: 'package.full',
              }}
            >
              <HeatMap />
            </YMaps>
          </div>
        </div>
      </div>
    </>
  )
}
