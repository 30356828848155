import {Editor} from '@tinymce/tinymce-react'
import axios from 'axios'
import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore:
import Dropzone from 'react-dropzone'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {MAIN_STATUSES, swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  // TinyMCE confs
  const textRuEditorRef = useRef(null)
  const textUzEditorRef = useRef(null)
  const textEnEditorRef = useRef(null)
  // --------

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    image_file: Yup.mixed(),
    url: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    title_ru: Yup.string(),
    title_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    title_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    text_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    text_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    text_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    seo_title_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_title_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_title_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_keyword_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_keyword_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_keyword_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_description_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    is_active: Yup.number().integer(),
  })

  const initialValues = {
    image_file: '',
    image: '',
    url: '',
    title_ru: '',
    title_uz: '',
    title_en: '',
    description_ru: '',
    description_uz: '',
    description_en: '',
    text_ru: '',
    text_uz: '',
    text_en: '',
    seo_title_ru: '',
    seo_title_uz: '',
    seo_title_en: '',
    seo_keyword_ru: '',
    seo_keyword_uz: '',
    seo_keyword_en: '',
    seo_description_ru: '',
    seo_description_uz: '',
    seo_description_en: '',
    is_active: 0,
  }

  if (!!props.item && !!props.item.id) {
    initialValues.url = props.item.url
    initialValues.image = props.item.image
    initialValues.title_ru = props.item.title_ru
    initialValues.title_uz = props.item.title_uz
    initialValues.title_en = props.item.title_en
    initialValues.description_ru = props.item.description_ru
    initialValues.description_uz = props.item.description_uz
    initialValues.description_en = props.item.description_en
    initialValues.text_ru = props.item.text_ru
    initialValues.text_uz = props.item.text_uz
    initialValues.text_en = props.item.text_en
    initialValues.seo_title_ru = props.item.seo_title_ru
    initialValues.seo_title_uz = props.item.seo_title_uz
    initialValues.seo_title_en = props.item.seo_title_en
    initialValues.seo_keyword_ru = props.item.seo_keyword_ru
    initialValues.seo_keyword_uz = props.item.seo_keyword_uz
    initialValues.seo_keyword_en = props.item.seo_keyword_en
    initialValues.seo_description_ru = props.item.seo_description_ru
    initialValues.seo_description_uz = props.item.seo_description_uz
    initialValues.seo_description_en = props.item.seo_description_en
    initialValues.is_active = props.item.is_active ? 1 : 0
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      let user = authHelper.getAuth()
      if (!user) return

      // @ts-ignore:
      let text_ru = textRuEditorRef.current.getContent()
      // @ts-ignore:
      let text_uz = textUzEditorRef.current.getContent()
      // @ts-ignore:
      let text_en = textEnEditorRef.current.getContent()

      try {
        const res = await axios.put(
          `/news/${props.item.id}`,
          {
            author_id: user.id,
            id: props.item.id,
            image_file: values.image_file || null,
            image: values.image,
            url: values.url,
            title_ru: values.title_ru,
            title_uz: values.title_uz,
            title_en: values.title_en,
            description_ru: values.description_ru,
            description_uz: values.description_uz,
            description_en: values.description_en,
            text_ru: text_ru,
            text_uz: text_uz,
            text_en: text_en,
            seo_title_ru: values.seo_title_ru,
            seo_title_uz: values.seo_title_uz,
            seo_title_en: values.seo_title_en,
            seo_keyword_ru: values.seo_keyword_ru,
            seo_keyword_uz: values.seo_keyword_uz,
            seo_keyword_en: values.seo_keyword_en,
            seo_description_ru: values.seo_description_ru,
            seo_description_uz: values.seo_description_uz,
            seo_description_en: values.seo_description_en,
            // @ts-ignore:
            is_active: values.is_active == 1 ? true : false,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.is_active == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_active == value.id}
            onChange={(e) => formik.setFieldValue('is_active', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <>
      <Modal
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.URL'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('url')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.url && formik.errors.url},
                  {
                    'is-valid': formik.touched.url && !formik.errors.url,
                  }
                )}
                value={formik.values.url}
              />
            </div>
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_RU'})}</label>
              <Dropzone
                multiple={false}
                onDrop={(files) => formik.setFieldValue('image_file', files[0])}
              >
                {({getRootProps, getInputProps}) => (
                  <div
                    {...getRootProps({
                      className: clsx(
                        'form-control c-dropzone',
                        {
                          'is-invalid': formik.touched.image_file && formik.errors.image_file,
                        },
                        {
                          'is-valid': formik.touched.image_file && !formik.errors.image_file,
                        }
                      ),
                    })}
                  >
                    <input {...getInputProps()} />
                    <p className='m-0'>
                      {!!formik.values.image_file
                        ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED_RU'})
                        : intl.formatMessage({id: 'COMMON.SELECT_PICTURE_RU'})}
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>
            <div className='col-md-12'>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_ru'>
                    Русский язык
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_uz'>
                    O'zbek tili
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_en'>
                    English
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='tabContent'>
                <div className='tab-pane fade show active' id='kt_tab_pane_ru' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.TITLE_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('title_ru')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.title_ru && formik.errors.title_ru},
                          {
                            'is-valid': formik.touched.title_ru && !formik.errors.title_ru,
                          }
                        )}
                        value={formik.values.title_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.DESCRIPTION_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('description_ru')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.description_ru && formik.errors.description_ru,
                          },
                          {
                            'is-valid':
                              formik.touched.description_ru && !formik.errors.description_ru,
                          }
                        )}
                        value={formik.values.description_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.TEXT_RU'})}
                      </label>
                      <div
                        className={clsx(
                          'form-control p-0',
                          {'is-invalid': formik.touched.text_ru && formik.errors.text_ru},
                          {
                            'is-valid': formik.touched.text_ru && !formik.errors.text_ru,
                          }
                        )}
                      >
                        <Editor
                          tinymceScriptSrc={
                            process.env.PUBLIC_URL + '/tinymce/js/tinymce/tinymce.min.js'
                          }
                          // @ts-ignore:
                          onInit={(evt, editor) => (textRuEditorRef.current = editor)}
                          // initialValue={formik.values.text_ru}
                          onEditorChange={(val, editor) => {
                            formik.setFieldValue('text_ru', val)
                          }}
                          value={formik.values.text_ru}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo  | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist image link | ' +
                              'outdent indent',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            branding: false,
                            file_picker_types: 'image',
                            file_picker_callback: function (cb, value, meta) {
                              var input = document.createElement('input')
                              input.setAttribute('type', 'file')
                              input.setAttribute('accept', 'image/*')

                              input.onchange = function () {
                                // @ts-ignore:
                                var file = this.files[0]

                                var reader = new FileReader()
                                reader.onload = function () {
                                  var id = 'blobid' + new Date().getTime()
                                  // @ts-ignore:
                                  var blobCache = tinymce.activeEditor.editorUpload.blobCache
                                  // @ts-ignore:
                                  var base64 = reader.result.split(',')[1]
                                  var blobInfo = blobCache.create(id, file, base64)
                                  blobCache.add(blobInfo)

                                  cb(blobInfo.blobUri(), {title: file.name})
                                }
                                reader.readAsDataURL(file)
                              }

                              input.click()
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_TITLE_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_title_ru')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.seo_title_ru && formik.errors.seo_title_ru},
                          {
                            'is-valid': formik.touched.seo_title_ru && !formik.errors.seo_title_ru,
                          }
                        )}
                        value={formik.values.seo_title_ru}
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_keyword_ru')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_keyword_ru && formik.errors.seo_keyword_ru,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_keyword_ru && !formik.errors.seo_keyword_ru,
                          }
                        )}
                        value={formik.values.seo_keyword_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_description_ru')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_description_ru && formik.errors.seo_description_ru,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_description_ru &&
                              !formik.errors.seo_description_ru,
                          }
                        )}
                        value={formik.values.seo_description_ru}
                      />
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_uz' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.TITLE_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('title_uz')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.title_uz && formik.errors.title_uz},
                          {
                            'is-valid': formik.touched.title_uz && !formik.errors.title_uz,
                          }
                        )}
                        value={formik.values.title_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.DESCRIPTION_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('description_uz')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.description_uz && formik.errors.description_uz,
                          },
                          {
                            'is-valid':
                              formik.touched.description_uz && !formik.errors.description_uz,
                          }
                        )}
                        value={formik.values.description_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.TEXT_UZ'})}
                      </label>
                      <div
                        className={clsx(
                          'form-control p-0',
                          {'is-invalid': formik.touched.text_uz && formik.errors.text_uz},
                          {
                            'is-valid': formik.touched.text_uz && !formik.errors.text_uz,
                          }
                        )}
                      >
                        <Editor
                          tinymceScriptSrc={
                            process.env.PUBLIC_URL + '/tinymce/js/tinymce/tinymce.min.js'
                          }
                          // @ts-ignore:
                          onInit={(evt, editor) => (textUzEditorRef.current = editor)}
                          onEditorChange={(val, editor) => {
                            formik.setFieldValue('text_uz', val)
                          }}
                          value={formik.values.text_uz}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo  | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist image link | ' +
                              'outdent indent',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            branding: false,
                            file_picker_types: 'image',
                            file_picker_callback: function (cb, value, meta) {
                              var input = document.createElement('input')
                              input.setAttribute('type', 'file')
                              input.setAttribute('accept', 'image/*')

                              input.onchange = function () {
                                // @ts-ignore:
                                var file = this.files[0]

                                var reader = new FileReader()
                                reader.onload = function () {
                                  var id = 'blobid' + new Date().getTime()
                                  // @ts-ignore:
                                  var blobCache = tinymce.activeEditor.editorUpload.blobCache
                                  // @ts-ignore:
                                  var base64 = reader.result.split(',')[1]
                                  var blobInfo = blobCache.create(id, file, base64)
                                  blobCache.add(blobInfo)

                                  cb(blobInfo.blobUri(), {title: file.name})
                                }
                                reader.readAsDataURL(file)
                              }

                              input.click()
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_TITLE_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_title_uz')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.seo_title_uz && formik.errors.seo_title_uz},
                          {
                            'is-valid': formik.touched.seo_title_uz && !formik.errors.seo_title_uz,
                          }
                        )}
                        value={formik.values.seo_title_uz}
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_keyword_uz')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_keyword_uz && formik.errors.seo_keyword_uz,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_keyword_uz && !formik.errors.seo_keyword_uz,
                          }
                        )}
                        value={formik.values.seo_keyword_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_description_uz')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_description_uz && formik.errors.seo_description_uz,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_description_uz &&
                              !formik.errors.seo_description_uz,
                          }
                        )}
                        value={formik.values.seo_description_uz}
                      />
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_en' role='tabpanel'>
                  <div className='row'>
                    {' '}
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.TITLE_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('title_en')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.title_en && formik.errors.title_en},
                          {
                            'is-valid': formik.touched.title_en && !formik.errors.title_en,
                          }
                        )}
                        value={formik.values.title_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.DESCRIPTION_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('description_en')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.description_en && formik.errors.description_en,
                          },
                          {
                            'is-valid':
                              formik.touched.description_en && !formik.errors.description_en,
                          }
                        )}
                        value={formik.values.description_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.TEXT_EN'})}
                      </label>
                      <div
                        className={clsx(
                          'form-control p-0',
                          {'is-invalid': formik.touched.text_en && formik.errors.text_en},
                          {
                            'is-valid': formik.touched.text_en && !formik.errors.text_en,
                          }
                        )}
                      >
                        <Editor
                          tinymceScriptSrc={
                            process.env.PUBLIC_URL + '/tinymce/js/tinymce/tinymce.min.js'
                          }
                          // @ts-ignore:
                          onInit={(evt, editor) => (textEnEditorRef.current = editor)}
                          onEditorChange={(val, editor) => {
                            formik.setFieldValue('text_en', val)
                          }}
                          value={formik.values.text_en}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo  | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist image link | ' +
                              'outdent indent',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            branding: false,
                            file_picker_types: 'image',
                            file_picker_callback: function (cb, value, meta) {
                              var input = document.createElement('input')
                              input.setAttribute('type', 'file')
                              input.setAttribute('accept', 'image/*')

                              input.onchange = function () {
                                // @ts-ignore:
                                var file = this.files[0]

                                var reader = new FileReader()
                                reader.onload = function () {
                                  var id = 'blobid' + new Date().getTime()
                                  // @ts-ignore:
                                  var blobCache = tinymce.activeEditor.editorUpload.blobCache
                                  // @ts-ignore:
                                  var base64 = reader.result.split(',')[1]
                                  var blobInfo = blobCache.create(id, file, base64)
                                  blobCache.add(blobInfo)

                                  cb(blobInfo.blobUri(), {title: file.name})
                                }
                                reader.readAsDataURL(file)
                              }

                              input.click()
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_TITLE_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_title_en')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.seo_title_en && formik.errors.seo_title_en},
                          {
                            'is-valid': formik.touched.seo_title_en && !formik.errors.seo_title_en,
                          }
                        )}
                        value={formik.values.seo_title_en}
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_keyword_en')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_keyword_en && formik.errors.seo_keyword_en,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_keyword_en && !formik.errors.seo_keyword_en,
                          }
                        )}
                        value={formik.values.seo_keyword_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_description_en')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_description_en && formik.errors.seo_description_en,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_description_en &&
                              !formik.errors.seo_description_en,
                          }
                        )}
                        value={formik.values.seo_description_en}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>
            <div className='col-md-6'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.STATUS'})}</label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-danger me-auto btn-sm'
            // @ts-ignore
            onClick={() => (handleClose(), deleteModalRef.current.showModal())}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button>
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
