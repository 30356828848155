import axios from 'axios'
import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore
import 'react-datepicker/dist/react-datepicker.css'

// @ts-ignore:

// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {MAIN_STATUSES, swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({})

  const initialValues = {
    name: '',
    pin: '',
    password: '',
    phone: '',
    terminal_id: '',
    is_active: 0,
  }

  if (!!props.item && !!props.item.id) {
    initialValues.name = props.item.name
    initialValues.pin = props.item.pin
    initialValues.password = props.item.password
    initialValues.phone = props.item.phone
    initialValues.terminal_id = props.item.terminalId
    initialValues.is_active = props.item.is_active ? 1 : 0
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(
          `/courier/${props.item.id}`,
          {
            name: values.name,
            pin: values.pin,
            password: values.password,
            phone: values.phone,
            is_active: values.is_active == 1 ? true : false,
            terminal_id: values.terminal_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2 z-0', {
            active: formik.values.is_active == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_active == value.id}
            onChange={(e) => formik.setFieldValue('is_active', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME2'})}</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                {...formik.getFieldProps('name')}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.BRANCH'})}</label>
              <Select
                classNamePrefix='form-control'
                styles={selectStyles}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () =>
                    formik.touched.terminal_id && !formik.errors.terminal_id
                      ? 'form-select p-0 border-success'
                      : formik.touched.terminal_id && formik.errors.terminal_id
                      ? 'form-select p-0 border-danger'
                      : 'form-select p-0',
                }}
                options={props.branches.map((x: any) => ({
                  value: x.id,
                  label: x.name_ru,
                }))}
                onChange={(selectedItem: any) => {
                  formik.setFieldValue('terminal_id', selectedItem.value)
                }}
                value={{
                  value:
                    (props.branches.find((x: any) => x.id == formik.values.terminal_id) || {}).id ||
                    null,
                  label:
                    (props.branches.find((x: any) => x.id == formik.values.terminal_id) || {})
                      .name_ru || '',
                }}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.PIN_CODE'})}</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.pin && formik.errors.pin},
                  {
                    'is-valid': formik.touched.pin && !formik.errors.pin,
                  }
                )}
                {...formik.getFieldProps('pin')}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.PASSWORD'})}</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                {...formik.getFieldProps('password')}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.AVAILABLE'})}</label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>

          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
