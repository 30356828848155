import axios from 'axios'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {swal} from '../../../../_metronic/helpers/swal'

import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'

export const ItemRoleModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    description: Yup.string(),
    key: Yup.string()
      .required()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    isActive: Yup.number().integer(),
  })

  const initialValues = {
    title: '',
    description: '',
    key: '',
    isActive: 1,
  }

  if (!!props.item && !!props.item.id) {
    initialValues.title = props.item.title
    initialValues.key = props.item.key
    initialValues.description = props.item.description
    initialValues.isActive = props.item.is_active ? 1 : 0
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(
          `/roles/${props.item.id}`,
          {
            title: values.title,
            key: values.key,
            description: values.description,
            // @ts-ignore:
            is_active: !!Number(values.isActive),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        // let msg = (((err || {}).response || {}).data || {}).message

        // setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: msg || err.message}))
        setSubmitting(false)
        setLoading(false)
        return
      }
      setShow(false)
      setSubmitting(false)
      setLoading(false)
      props.refreshData()
    },
  })

  console.log(props.item)

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
      >
        <Modal.Header>
          <h3 className='modal-title'>
            {intl.formatMessage({id: 'COMMON.CHANGE'})} ({props.item?.name})
          </h3>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_update_role_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_update_role_header'
              data-kt-scroll-wrappers='#kt_modal_update_role_scroll'
              data-kt-scroll-offset='300px'
              style={{maxHeight: 273}}
            >
              <div className='fv-row'>
                <label className='fs-5  form-label mb-2'>Role Permissions</label>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-dashed fs-6 gy-5'>
                    <tbody className='text-gray-600 fw-semibold'>
                      <tr>
                        <td className='text-gray-800'>
                          Administrator Access
                          <span
                            className='ms-1'
                            data-bs-toggle='tooltip'
                            aria-label='Allows a full access to the system'
                            data-bs-original-title='Allows a full access to the system'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>
                          </span>{' '}
                        </td>
                        <td>
                          <label className='form-check form-check-sm form-check-custom form-check-solid me-9'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              defaultValue=''
                              id='kt_roles_select_all'
                            />
                            <span className='form-check-label'>Select all</span>
                          </label>
                        </td>
                      </tr>
                      {props.item?.items?.map((item: any) => (
                        <tr>
                          <td className='text-gray-800'>{item.name}</td>
                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue=''
                                  name='user_management_read'
                                />
                                <span className='form-check-label'>Active</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-danger me-auto btn-sm'
            // @ts-ignore
            onClick={() => (handleClose(), deleteModalRef.current.showModal())}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button>
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>{' '}
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
