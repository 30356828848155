import axios from 'axios'
import React, {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import ImageViewer from 'react-simple-image-viewer'

import {getMediaUrl} from '../../../_metronic/helpers/general'

import ReactPaginate from 'react-paginate'
import {useSearchParams} from 'react-router-dom'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import Loader from '../../components/Loader'
import {useRoleContext} from '../../context/getAccessRoleUser'
import FooterCopyright from '../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'
import {ImagesModal} from './components/ImagesModal'
import {ItemModal} from './components/ItemModal'

export function RestaurantsIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  const [data, setData] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''

  const addModalRef = React.useRef()
  const itemModalRef = React.useRef()
  const imagesRef = React.useRef()

  // ImageViewer
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })

  // @ts-ignore
  let viewerImages = (data || []).map((x) => getMediaUrl('restaurants', x.cover))
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  // End of ImageViewer

  async function getData() {
    try {
      const res = await axios.get('/restaurants/')
      setData(res.data.data || [])
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }
  React.useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id == choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer'
          onClick={() => {
            if (!findRoleUser('restaurants-edit')) return
            itemClick(value)
            // @ts-ignore
            itemModalRef.current.showModal()
          }}
        >
          <td style={{width: 40}}>{i + 1}</td>
          <td>
            <img
              className='row-img rounded'
              src={getMediaUrl('restaurants', value.cover)}
              alt=''
              onClick={(e) => {
                e.stopPropagation()
                openImageViewer(i)
              }}
            />
          </td>
          <td>{value.name_ru}</td>
          {findRoleUser('restaurants-edit') && (
            <td className='text-end'>
              <button
                type='button'
                className='btn btn-info btn-sm'
                onClick={(e) => {
                  e.stopPropagation()
                  itemClick(value)
                  // @ts-ignore
                  imagesRef.current.showModal()
                }}
              >
                {intl.formatMessage({id: 'COMMON.IMAGES'})}
              </button>
            </td>
          )}
        </tr>
      )
    }

    return content
  }

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search

    return obj
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.RESTAURANTS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {findRoleUser('restaurants-add') && (
                <button
                  className='btn btn-success' // @ts-ignore
                  onClick={() => addModalRef.current.showModal()}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )}
            </div>
            <div className='card-toolbar'>
              {/* <button
                type='button'
                className='btn btn-light-primary btn-sm me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <i className='fa-solid fa-filter'></i>
                Filter
              </button> */}
            </div>
          </div>
        </div>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          {/* <div className='card-header'>
            <h2 className='card-title'>{intl.formatMessage({id: 'MENU.RESTAURANTS'})}</h2>
            <div className='card-toolbar'>
              <button
                className='btn btn-primary' // @ts-ignore
                onClick={() => addModalRef.current.showModal()}
              >
                {intl.formatMessage({id: 'COMMON.ADD'})}
              </button>
            </div>
          </div> */}
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th style={{width: 40}}>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.COVER'})}</th>
                    <th>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</th>
                    {findRoleUser('restaurants-edit') && <th></th>}
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={2}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal item={choosenItem} refreshData={refreshData} ref={itemModalRef} />
      <AddModal refreshData={refreshData} ref={addModalRef} />
      <ImagesModal item={choosenItem} refreshData={refreshData} ref={imagesRef} />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
