import clsx from 'clsx'
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'

import axios from 'axios'
import moment from 'moment'

import ReactPaginate from 'react-paginate'

// COMPONENTS
import FooterCopyright from '../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'
import {ItemModal} from './components/ItemModal'

// HELPERS
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import Loader from '../../components/Loader'
import {useRoleContext} from '../../context/getAccessRoleUser'

export function CouriersIndex() {
  const intl = useIntl()
  const navigate = useNavigate()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''
  let with_delete = searchParams.get('with_delete') === 'true' ? true : false

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [branches, setBranches] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })

  const addModalRef = React.useRef<any>()
  const itemModalRef = React.useRef<any>()

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
      with_delete?: string | boolean
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search
    if (!!with_delete) obj.with_delete = with_delete

    return obj
  }
  const buildQueryParams = () => {
    let query = `?page=${page}&page_size=${page_size}`

    if (!!with_delete) query += `&with_delete=${with_delete}`
    if (!!multi_search) query += `&multi_search=${multi_search}`

    return query
  }

  async function getData() {
    try {
      const res = await axios.get(`courier/all/${buildQueryParams()}`)

      setData(res.data || [])

      const branchesRes = await axios.get('/terminals/all/')
      setBranches(branchesRes.data.data)

      // setPaginationData({
      //   count: res.data.count,
      //   page: res.data.page,
      //   page_size: res.data.page_size,
      //   totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      // })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getCities() {
    try {
      const result = await axios.get('/regions/?is_page_all=true')
      setCities(result.data.data)
    } catch (err: any) {
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    getData()
    getCities()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id == choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []
    let content = []
    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className={`c-pointer ${!value.is_deleted || 'bg-danger bg-opacity-20'}`}
          onClick={() => {
            navigate(`/couriers/${value.id}`)
          }}
        >
          <td>{i + 1}</td>
          <td>{value.name}</td>
          <td>
            {(value.terminal || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
          </td>
          <td>{value.pin || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{value.password || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{value.phone || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td className='text-center'>
            <span
              className={clsx(
                'badge badge-light-success ',
                {'badge-light-success': value?.is_active},
                {
                  'badge-light-danger': !value?.is_active,
                }
              )}
            >
              {value?.is_active
                ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
            </span>
          </td>
          <td className='text-center'>{moment(value.synced_at).format('DD/MM/YYYY HH:mm')}</td>
          {findRoleUser('users-edit') && (
            <td>
              <button
                className='btn link btn-sm p-0'
                data-bs-toggle='modal'
                onClick={(e) => (
                  itemClick(value), itemModalRef.current.showModal(), e.stopPropagation()
                )}
              >
                <i className='fa-regular fa-pen-to-square text-primary'></i>
              </button>
            </td>
          )}
        </tr>
      )
    }

    return content
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const clearSearchParams = () => {
    searchParams.delete('with_delete')
    searchParams.delete('type')
    setSearchParams(searchParams)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.COURIERS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME2'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.BRANCH'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PIN_CODE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PASSWORD'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                    <th className={'text-center'}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE'})}
                    </th>
                    <th className={'text-center'}>
                      {intl.formatMessage({id: 'COMMON.LAST_REVISION'})}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={2}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal
        item={choosenItem}
        refreshData={refreshData}
        cities={cities}
        ref={itemModalRef}
        branches={branches}
      />
      <AddModal refreshData={refreshData} cities={cities} ref={addModalRef} branches={branches} />
    </>
  )
}
