import axios from 'axios'
import clsx from 'clsx'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import { useFormik } from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { swal } from '../../../../_metronic/helpers/swal'

import { MAIN_STATUSES, swalDefaultConfig } from '../../../../_metronic/helpers/constants'
import { DeleteModal } from './DeleteModal'
import { MapModal } from './MapModal'
import { PolygonMapModal } from './PolygonMapModal'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()
  const mapModalRef = useRef()
  const polygonMapModalRef = useRef()

  const validationSchema = Yup.object().shape({
    name_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    address_ru: Yup.string().nullable(),
    description_ru: Yup.string().nullable(),
    name_uz: Yup.string().nullable(),
    address_uz: Yup.string().nullable(),
    description_uz: Yup.string().nullable(),
    name_en: Yup.string().nullable(),
    address_en: Yup.string().nullable(),
    description_en: Yup.string().nullable(),
    phone: Yup.string().nullable().nullable(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    time: Yup.string().nullable(),
    self_time: Yup.string().nullable(),
    open_time: Yup.string().nullable(),
    close_time: Yup.string().nullable(),
    su_open_time: Yup.string().nullable(),
    su_close_time: Yup.string().nullable(),
    tg_group_id: Yup.string().nullable(),
    is_active: Yup.number().integer(),
    coordinates: Yup.string().nullable(),
    polygon_color: Yup.string().nullable(),
  })

  const initialValues = {
    region_id: '',
    organization_id: '',
    business_id: '',
    tg_group_id: '',
    name_ru: '',
    name_uz: '',
    name_en: '',
    address_ru: '',
    address_uz: '',
    address_en: '',
    description_ru: '',
    description_uz: '',
    description_en: '',
    phone: '',
    latitude: '',
    longitude: '',
    time: '',
    self_time: '',
    open_time: '',
    close_time: '',
    su_open_time: '',
    su_close_time: '',
    is_active: 1,
    coordinates: '',
    polygon_color: '',
  }

  if (!!props.item && !!props.item.id) {
    initialValues.region_id = props.item.region_id
    initialValues.organization_id = props.item.organization_id
    initialValues.business_id = props.item.business_id
    initialValues.tg_group_id = props.item.tg_group_id
    initialValues.name_ru = props.item.name_ru
    initialValues.name_uz = props.item.name_uz
    initialValues.name_en = props.item.name_en
    initialValues.address_ru = props.item.address_ru
    initialValues.address_uz = props.item.address_uz
    initialValues.address_en = props.item.address_en
    initialValues.description_ru = props.item.description_ru
    initialValues.description_uz = props.item.description_uz
    initialValues.description_en = props.item.description_en
    initialValues.phone = props.item.phone
    initialValues.latitude = props.item.latitude
    initialValues.longitude = props.item.longitude
    initialValues.time = props.item.time
    initialValues.self_time = props.item.self_time
    initialValues.open_time = props.item.open_time
    initialValues.close_time = props.item.close_time
    initialValues.su_open_time = props.item.su_open_time
    initialValues.su_close_time = props.item.su_close_time
    initialValues.is_active = props.item.is_active ? 1 : 0
    initialValues.coordinates = props.item.coordinates
    initialValues.polygon_color = props.item.polygon_color || '#0000FF'
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(`/terminals/${props.item.id}`, {
          id: props.item.id,
          region_id: values.region_id,
          organization_id: values.organization_id,
          business_id: values.business_id,
          tg_group_id: values.tg_group_id,
          name_ru: values.name_ru,
          name_uz: values.name_uz,
          name_en: values.name_en,
          address_ru: values.address_ru,
          address_uz: values.address_uz,
          address_en: values.address_en,
          description_ru: values.description_ru,
          description_uz: values.description_uz,
          description_en: values.description_en,
          phone: values.phone,
          latitude: `${values.latitude}`,
          longitude: `${values.longitude}`,
          time: values.time,
          self_time: values.self_time,
          open_time: values.open_time,
          close_time: values.close_time,
          su_open_time: values.su_open_time,
          su_close_time: values.su_close_time,
          // @ts-ignore:
          is_active: values.is_active == 1 ? true : false,
          // coordinates: JSON.stringify(values.coordinates || []),
          coordinates: values.coordinates || '[]',
          polygon_color: values.polygon_color,
        })

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        setSubmitting(false)

        return
      }

      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.is_active == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_active == value.id}
            onChange={(e) => formik.setFieldValue('is_active', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <>
      <Modal
        className='es-modal'
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}
            <div className='col-md-12'>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_ru'>
                    Русский язык
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_uz'>
                    O'zbek tili
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_en'>
                    English
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='tabContent'>
                <div className='tab-pane fade show active' id='kt_tab_pane_ru' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.NAME_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name_ru')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                          {
                            'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                          }
                        )}
                        value={formik.values.name_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.ADDRESS_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('address_ru')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.address_ru && formik.errors.address_ru},
                          {
                            'is-valid': formik.touched.address_ru && !formik.errors.address_ru,
                          }
                        )}
                        value={formik.values.address_ru}
                      />
                    </div>
                  </div>
                  <div className='col-md-12 mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'ITEM.DESCRIPTION_RU'})}
                    </label>
                    <textarea
                      {...formik.getFieldProps('description_ru')}
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid':
                            formik.touched.description_ru && formik.errors.description_ru,
                        },
                        {
                          'is-valid':
                            formik.touched.description_ru && !formik.errors.description_ru,
                        }
                      )}
                      value={formik.values.description_ru}
                      rows={4}
                    />
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_uz' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.NAME_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name_uz')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                          {
                            'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                          }
                        )}
                        value={formik.values.name_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.ADDRESS_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('address_uz')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.address_uz && formik.errors.address_uz},
                          {
                            'is-valid': formik.touched.address_uz && !formik.errors.address_uz,
                          }
                        )}
                        value={formik.values.address_uz}
                      />
                    </div>
                  </div>

                  <div className='col-md-12 mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'ITEM.DESCRIPTION_UZ'})}
                    </label>
                    <textarea
                      {...formik.getFieldProps('description_uz')}
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid':
                            formik.touched.description_uz && formik.errors.description_uz,
                        },
                        {
                          'is-valid':
                            formik.touched.description_uz && !formik.errors.description_uz,
                        }
                      )}
                      value={formik.values.description_uz}
                      rows={4}
                    />
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_en' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.NAME_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name_en')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                          {
                            'is-valid': formik.touched.name_en && !formik.errors.name_en,
                          }
                        )}
                        value={formik.values.name_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.ADDRESS_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('address_en')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.address_en && formik.errors.address_en},
                          {
                            'is-valid': formik.touched.address_en && !formik.errors.address_en,
                          }
                        )}
                        value={formik.values.address_en}
                      />
                    </div>
                  </div>

                  <div className='col-md-12 mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'ITEM.DESCRIPTION_EN'})}
                    </label>
                    <textarea
                      {...formik.getFieldProps('description_en')}
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid':
                            formik.touched.description_en && formik.errors.description_en,
                        },
                        {
                          'is-valid':
                            formik.touched.description_en && !formik.errors.description_en,
                        }
                      )}
                      value={formik.values.description_en}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('phone')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.phone && formik.errors.phone},
                  {
                    'is-valid': formik.touched.phone && !formik.errors.phone,
                  }
                )}
                value={formik.values.phone}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'MENU.LOCATION'})}</label>

              <button
                className='btn btn-outline-primary active form-control'
                onClick={(e) => {
                  e.preventDefault()
                  // @ts-ignore
                  mapModalRef.current.showModal(formik.values.latitude, formik.values.longitude)
                }}
              >
                {intl.formatMessage({id: 'COMMON.POINT_ON_MAP'})}
              </button>
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.POLYGON'})}</label>

              <button
                className='btn btn-outline-warning active form-control'
                onClick={(e) => {
                  e.preventDefault()
                  // @ts-ignore
                  polygonMapModalRef.current.showModal()
                }}
              >
                {intl.formatMessage({id: 'COMMON.DRAW_IN_MAP'})}
              </button>
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.POLYGON_COLOR'})}
              </label>
              <select
                className='form-select'
                {...formik.getFieldProps('polygon_color')}
                value={formik.values.polygon_color}
              >
                {(props.COLORS_DICT || []).map((color: any) => (
                  <option key={'colorOption-' + color.value} value={color.value}>
                    {color.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.DEL_TIME'})}</label>
              <input
                type='time'
                {...formik.getFieldProps('time')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.time && formik.errors.time},
                  {
                    'is-valid': formik.touched.time && !formik.errors.time,
                  }
                )}
                value={formik.values.time}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.SELF_TIME'})}</label>
              <input
                type='time'
                {...formik.getFieldProps('self_time')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.self_time && formik.errors.self_time},
                  {
                    'is-valid': formik.touched.self_time && !formik.errors.self_time,
                  }
                )}
                value={formik.values.self_time}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.OPEN_TIME'})}</label>
              <input
                type='time'
                {...formik.getFieldProps('open_time')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.open_time && formik.errors.open_time},
                  {
                    'is-valid': formik.touched.open_time && !formik.errors.open_time,
                  }
                )}
                value={formik.values.open_time}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.CLOSE_TIME'})}</label>
              <input
                type='time'
                {...formik.getFieldProps('close_time')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.close_time && formik.errors.close_time},
                  {
                    'is-valid': formik.touched.close_time && !formik.errors.close_time,
                  }
                )}
                value={formik.values.close_time}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.OPEN_TIME2'})}</label>
              <input
                type='time'
                {...formik.getFieldProps('su_open_time')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.su_open_time && formik.errors.su_open_time},
                  {
                    'is-valid': formik.touched.su_open_time && !formik.errors.su_open_time,
                  }
                )}
                value={formik.values.su_open_time}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.CLOSE_TIME2'})}</label>
              <input
                type='time'
                {...formik.getFieldProps('su_close_time')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.su_close_time && formik.errors.su_close_time},
                  {
                    'is-valid': formik.touched.su_close_time && !formik.errors.su_close_time,
                  }
                )}
                value={formik.values.su_close_time}
              />
            </div>
            <div className='col-md-6'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.TG_GROUP'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('tg_group_id')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.tg_group_id && formik.errors.tg_group_id},
                  {
                    'is-valid': formik.touched.tg_group_id && !formik.errors.tg_group_id,
                  }
                )}
                value={formik.values.tg_group_id}
              />
            </div>
            <div className='col-md-6'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.STATUS'})}</label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
                type='button'
                className='btn btn-danger me-auto'
                data-bs-toggle='modal'
                // @ts-ignore
                onClick={() => deleteModalRef.current.showModal()}
              >
                {intl.formatMessage({id: 'COMMON.DELETE'})}
              </button> */}
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>

      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
      <MapModal
        item={props.item}
        ref={mapModalRef}
        refreshData={props.refreshData}
        formik={formik}
      />
      <PolygonMapModal
        item={props.item}
        ref={polygonMapModalRef}
        refreshData={props.refreshData}
        formik={formik}
      />
    </>
  )
})
