import axios from 'axios'
import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore:
import Dropzone from 'react-dropzone'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {MAIN_STATUSES, swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    image_file_ru: Yup.mixed().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    image_file_uz: Yup.mixed(),
    image_file_en: Yup.mixed(),
    name_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    description_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    name_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    name_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    url: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    position: Yup.number()
      .integer()
      .min(-1, intl.formatMessage({id: 'VALIDATION.MIN_POSITION'}, {number: 0})),
    is_active: Yup.boolean(),
  })

  let initialValues = {
    image_file_ru: '',
    image_file_uz: '',
    image_file_en: '',
    name_ru: '',
    name_uz: '',
    name_en: '',
    description_ru: '',
    description_uz: '',
    description_en: '',
    url: '',
    position: 0,
    is_active: true,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.post(
          '/sliders/',
          {
            author_id: user.id,
            url: values.url,
            position: values.position || null,
            image_file_ru: values.image_file_ru || null,
            image_file_uz: values.image_file_uz || null,
            image_file_en: values.image_file_en || null,
            name_ru: values.name_ru,
            name_uz: values.name_uz,
            name_en: values.name_en,
            description_ru: values.description_ru,
            description_uz: values.description_uz,
            description_en: values.description_en,
            // @ts-ignore:
            is_active: values.is_active == 1 ? true : false,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}), 'success')
        )
      } catch (err: any) {
        setLoading(false)
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }

        return
      }
      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.is_active == value.boolean,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.boolean}
            checked={formik.values.is_active == value.boolean}
            onChange={(e) => formik.setFieldValue('is_active', JSON.parse(e.currentTarget.value))}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='col-md-12 mb-2'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.URL'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('url')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.url && formik.errors.url},
                {
                  'is-valid': formik.touched.url && !formik.errors.url,
                }
              )}
              value={formik.values.url}
            />
          </div>
          <div className='col-12 my-2'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-12'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_ru'>
                  Русский язык
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_uz'>
                  O'zbek tili
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_en'>
                  English
                </a>
              </li>
            </ul>
            <div className='tab-content' id='tabContent'>
              <div className='tab-pane fade show active' id='kt_tab_pane_ru' role='tabpanel'>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_RU'})}</label>
                  <Dropzone
                    multiple={false}
                    onDrop={(files) => formik.setFieldValue('image_file_ru', files[0])}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: clsx(
                            'form-control c-dropzone',
                            {
                              'is-invalid':
                                formik.touched.image_file_ru && formik.errors.image_file_ru,
                            },
                            {
                              'is-valid':
                                formik.touched.image_file_ru && !formik.errors.image_file_ru,
                            }
                          ),
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className='m-0'>
                          {!!formik.values.image_file_ru
                            ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED_RU'})
                            : intl.formatMessage({id: 'COMMON.SELECT_PICTURE_RU'})}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('name_ru')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                      {
                        'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                      }
                    )}
                    value={formik.values.name_ru}
                  />
                </div>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'ITEM.DESCRIPTION_RU'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('description_ru')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.description_ru && formik.errors.description_ru},
                      {
                        'is-valid': formik.touched.description_ru && !formik.errors.description_ru,
                      }
                    )}
                    value={formik.values.description_ru}
                    rows={4}
                  />
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_uz' role='tabpanel'>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_UZ'})}</label>
                  <Dropzone
                    multiple={false}
                    onDrop={(files) => formik.setFieldValue('image_file_uz', files[0])}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: clsx(
                            'form-control c-dropzone',
                            {
                              'is-invalid':
                                formik.touched.image_file_uz && formik.errors.image_file_uz,
                            },
                            {
                              'is-valid':
                                formik.touched.image_file_uz && !formik.errors.image_file_uz,
                            }
                          ),
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className='m-0'>
                          {!!formik.values.image_file_uz
                            ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED_UZ'})
                            : intl.formatMessage({id: 'COMMON.SELECT_PICTURE_UZ'})}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_UZ'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('name_uz')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                      {
                        'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                      }
                    )}
                    value={formik.values.name_uz}
                  />
                </div>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'ITEM.DESCRIPTION_UZ'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('description_uz')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.description_uz && formik.errors.description_uz},
                      {
                        'is-valid': formik.touched.description_uz && !formik.errors.description_uz,
                      }
                    )}
                    value={formik.values.description_uz}
                    rows={4}
                  />
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_en' role='tabpanel'>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_EN'})}</label>
                  <Dropzone
                    multiple={false}
                    onDrop={(files) => formik.setFieldValue('image_file_en', files[0])}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: clsx(
                            'form-control c-dropzone',
                            {
                              'is-invalid':
                                formik.touched.image_file_en && formik.errors.image_file_en,
                            },
                            {
                              'is-valid':
                                formik.touched.image_file_en && !formik.errors.image_file_en,
                            }
                          ),
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className='m-0'>
                          {!!formik.values.image_file_en
                            ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED_EN'})
                            : intl.formatMessage({id: 'COMMON.SELECT_PICTURE_EN'})}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>

                <div className='col-md-12 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_EN'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('name_en')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                      {
                        'is-valid': formik.touched.name_en && !formik.errors.name_en,
                      }
                    )}
                    value={formik.values.name_en}
                  />
                </div>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'ITEM.DESCRIPTION_EN'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('description_en')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.description_en && formik.errors.description_en},
                      {
                        'is-valid': formik.touched.description_en && !formik.errors.description_en,
                      }
                    )}
                    value={formik.values.description_en}
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 my-2'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-6'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.POSITION'})}</label>
            <input
              type='number'
              {...formik.getFieldProps('position')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.position && formik.errors.position},
                {
                  'is-valid': formik.touched.position && !formik.errors.position,
                }
              )}
              min={0}
              value={formik.values.position}
            />
          </div>
          <div className='col-md-6'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.STATUS'})}</label>
            <div className='form-check form-check-custom form-check-solid'>
              <div
                className='btn-group w-100'
                data-kt-buttons='true'
                data-kt-buttons-target='[data-kt-button]'
              >
                {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
