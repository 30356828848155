import axios from 'axios'
import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore:
import Dropzone from 'react-dropzone'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

export const AddImageModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    props.parentRef.current.showModal()
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    photo_file: Yup.mixed().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  const initialValues = {
    photo_file: '',
    title_ru: '',
    title_uz: '',
    title_en: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.post(
          `/restaurant-photos/`,
          {
            author_id: user.id,
            restaurant_id: props.parentItem.id,
            photo_file: values.photo_file || null,
            title_ru: values.title_ru,
            title_uz: values.title_uz,
            title_en: values.title_en,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_DELETE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      handleClose()
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.IMAGE'})}</label>
            <Dropzone
              multiple={false}
              onDrop={(files) => formik.setFieldValue('photo_file', files[0])}
            >
              {({getRootProps, getInputProps}) => (
                <div
                  {...getRootProps({
                    className: clsx(
                      'form-control c-dropzone',
                      {
                        'is-invalid': formik.touched.photo_file && formik.errors.photo_file,
                      },
                      {
                        'is-valid': formik.touched.photo_file && !formik.errors.photo_file,
                      }
                    ),
                  })}
                >
                  <input {...getInputProps()} />
                  <p className='m-0'>
                    {!!formik.values.photo_file
                      ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED'})
                      : intl.formatMessage({id: 'COMMON.SELECT_PICTURE'})}
                  </p>
                </div>
              )}
            </Dropzone>
          </div>
          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.TITLE_RU'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('title_ru')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.title_ru && formik.errors.title_ru},
                {
                  'is-valid': formik.touched.title_ru && !formik.errors.title_ru,
                }
              )}
              value={formik.values.title_ru}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.TITLE_UZ'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('title_uz')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.title_uz && formik.errors.title_uz},
                {
                  'is-valid': formik.touched.title_uz && !formik.errors.title_uz,
                }
              )}
              value={formik.values.title_uz}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.TITLE_EN'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('title_en')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.title_en && formik.errors.title_en},
                {
                  'is-valid': formik.touched.title_en && !formik.errors.title_en,
                }
              )}
              value={formik.values.title_en}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
