import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

const ENDPOINT = '/settings/'

let rowsCount = 0

// Fetch all
const fetchData = async () => {
  const response = await axios.get(ENDPOINT)

  return response.data.data
}

// Create a new row
const createRow = async (row) => {
  const {data} = await axios.post(ENDPOINT, row)
  return data
}

// Update an existing row
const updateRow = async (row) => {
  const {data} = await axios.put(`${ENDPOINT}${row.id}/`, row)
  return data
}

// Delete a row
const deleteRow = async (id) => {
  await axios.delete(`${ENDPOINT}${id}/`)
}

export const useSettings = () => {
  const queryClient = useQueryClient()

  // Fetch settings
  const settingsQuery = useQuery([ENDPOINT], () => fetchData(), {keepPreviousData: true})

  // Create a new setting
  const createMutation = useMutation(createRow, {
    onSuccess: () => queryClient.invalidateQueries([ENDPOINT]),
  })

  // Update a setting
  const updateMutation = useMutation(updateRow, {
    onSuccess: () => queryClient.invalidateQueries([ENDPOINT]),
  })

  // Delete a setting
  const deleteMutation = useMutation(deleteRow, {
    onSuccess: () => queryClient.invalidateQueries([ENDPOINT]),
  })

  return {settingsQuery, createMutation, updateMutation, deleteMutation, rowsCount}
}
