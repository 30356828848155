// @ts-nocheck
import axios from 'axios'
import React, {FC} from 'react'

import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import FooterCopyright from '../../../modules/FooterCopyright'
import {ItemRoleModal} from '../components/ItemRoleModal'

const RolesItemsTestIndex: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  const navigate = useNavigate()

  let {role_id} = useParams()

  const [choosenItem, setChoosenItem] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [role, setRole] = React.useState({})
  const [modules, setModules] = React.useState<any>([])
  const [modulesItems, setModulesItems] = React.useState([])
  const [roleModuleItems, setRoleModulesItems] = React.useState([])
  const [modulesItemsTree, setModulesItemsTree] = React.useState([])

  const itemRoleModalRef = React.useRef()

  async function getData() {
    try {
      let roleRes = await axios.get(`/roles/${role_id}`)
      if (!roleRes?.data?.id) return navigate('/roles')
      setRole(roleRes.data || {})

      let modulesRes = await axios.get('/modules/all/?is_page_all=true')
      setModules(modulesRes.data.modules || [])

      let modulesItemsRes = await axios.get('/module-items/?is_page_all=true')
      setModulesItems(modulesItemsRes.data.module_items || [])

      let roleModuleItemsRes = await axios.get(`/roles/item/${role_id}`)
      setRoleModulesItems(roleModuleItemsRes.data || [])
      setIsLoading(false)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setIsLoading(false)
    }
  }
  React.useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  React.useEffect(() => {
    let treeModulesItems = []
    for (const module of modules || []) {
      let moduleItems = [...(modulesItems || [])].filter((x) => x?.module.id == module.id)
      let treeModuleItems = []

      for (const item of moduleItems) {
        let roleItem = (roleModuleItems || []).find((x) => x.module_item_key == item.key)

        let treeModuleItem = {
          ...item,
          isActive: !!roleItem,
        }

        treeModuleItems.push(treeModuleItem)
      }

      let treeModule = {
        ...module,
        items: treeModuleItems,
      }
      treeModulesItems.push(treeModule)
    }

    setModulesItemsTree(treeModulesItems)
  }, [modules, modulesItems, roleModuleItems])

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(modulesItemsTree.find((x) => x.id == choosenItem.id) || null)
    }
  }, [modulesItemsTree])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const refreshData = async () => {
    await getData()
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.ROLES'}),
        path: '/roles',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'COMMON.KEYS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid pb-0'>
        <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9'>
          {modulesItemsTree.map((module: any) => (
            <div className='col-md-4'>
              <div className='card card-flush h-md-100'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>{module?.description}</h2>
                  </div>
                </div>
                <div className='card-body pt-1'>
                  <div className=' text-gray-600 mb-5'>
                    Total users with this role: {module.items.length}
                  </div>
                  <div className='d-flex flex-column text-gray-600'>
                    {module?.items?.map((item: any) => (
                      <div className='d-flex align-items-center py-2'>
                        {/* <span className='bullet bg-primary me-3' /> */}
                        {item.isActive ? (
                          <i class='fa-solid fa-minus text-primary me-3' />
                        ) : (
                          <i class='fa-solid fa-plus text-primary me-3' />
                        )}
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='card-footer flex-wrap pt-0'>
                  <Link
                    to='/metronic8/demo8/apps/user-management/roles/view.html'
                    className='btn btn-light btn-active-primary my-1 me-2'
                  >
                    {intl.formatMessage({id: 'COMMON.VIEW'})}
                  </Link>
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-primary my-1'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_update_role'
                    onClick={(e) => (
                      itemClick(module), itemRoleModalRef.current.showModal(), e.stopPropagation()
                    )}
                  >
                    {intl.formatMessage({id: 'COMMON.CHANGE'})}
                  </button>
                </div>
              </div>
            </div>
          ))}
          {/* <div className='ol-md-4'>
          <div className='card h-md-100'>
            <div className='card-body d-flex flex-center'>
              <button
                type='button'
                className='btn btn-clear d-flex flex-column flex-center'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_add_role'
              >
                <img
                  src='/metronic8/demo8/assets/media/illustrations/sketchy-1/4.png'
                  alt=''
                  className='mw-100 mh-150px mb-7'
                />
                <div className=' fs-3 text-gray-600 text-hover-primary'>Add New Role</div>
              </button>
            </div>
          </div>
        </div> */}
        </div>
      </div>

      {/* FOOTER */}
      <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
        <div
          className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

          {<FooterCopyright />}
        </div>
      </div>

      <ItemRoleModal item={choosenItem} refreshData={refreshData} ref={itemRoleModalRef} />
    </>
  )
}

export {RolesItemsTestIndex}
