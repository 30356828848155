/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useRoleContext} from '../../../../app/context/getAccessRoleUser'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const {findRoleUser, findRoleUserByArray} = useRoleContext()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {findRoleUserByArray([
        'statistics-location',
        'statistics-order-type',
        'statistics-payment',
        'statistics-products',
        'statistics-system',
        'statistics-terminals',
        'statistics-users',
        'statistics-courier',
        'statistics-abc',
        'statistics-xyz',
        'statistics-abcxyz',
        'statistics-rfm',
      ]) && (
        <AsideMenuItemWithSub
          to='/statistics'
          title={intl.formatMessage({id: 'MENU.STATS'})}
          icon='/media/icons/duotune/graphs/gra004.svg'
        >
          {findRoleUser('statistics-abc') && (
            <AsideMenuItem
              to='/statistics/abc'
              title={intl.formatMessage({id: 'MENU.ABC'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-abc') && (
            <AsideMenuItem
              to='/statistics/abc-clients'
              title={intl.formatMessage({id: 'MENU.ABC_CLIENTS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-xyz') && (
            <AsideMenuItem
              to='/statistics/xyz'
              title={intl.formatMessage({id: 'MENU.XYZ'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-abcxyz') && (
            <AsideMenuItem
              to='/statistics/abcxyz'
              title={intl.formatMessage({id: 'MENU.ABCXYZ'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-rfm') && (
            <AsideMenuItem
              to='/statistics/rfm'
              title={intl.formatMessage({id: 'MENU.RFM'})}
              hasBullet={true}
            />
          )}
          {/* {findRoleUser('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.CLIENTS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.TOP10'})}
              hasBullet={true}
            />
          )} */}

          {findRoleUser('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.ORDER_TYPE'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-courier') && (
            <AsideMenuItem
              to='/statistics/couriers'
              title={intl.formatMessage({id: 'MENU.COURIERS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-terminals') && (
            <AsideMenuItem
              to='/statistics/terminals'
              title={intl.formatMessage({id: 'MENU.BRANCHES'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-payment-type') && (
            <AsideMenuItem
              to='/statistics/payment-type'
              title={intl.formatMessage({id: 'MENU.PAYMENT_TYPE'})}
              hasBullet={true}
            />
          )}
          {/* {findRoleUser('statistics-location') && (
            <AsideMenuItem
              to='/statistics/location'
              title={intl.formatMessage({id: 'MENU.LOCATION'})}
              hasBullet={true}
            />
          )} */}
          {findRoleUser('statistics-products') && (
            <AsideMenuItem
              to='/statistics/products'
              title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-system') && (
            <AsideMenuItem
              to='/statistics/system'
              title={intl.formatMessage({id: 'MENU.SYSTEM'})}
              hasBullet={true}
            />
          )}

          {findRoleUser('statistics-users') && (
            <AsideMenuItem
              to='/statistics/users'
              title={intl.formatMessage({id: 'COMMON.OPERATORS'})}
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {findRoleUserByArray(['orders-view', 'orders-add', 'couriers-map-view']) && (
        <AsideMenuItemWithSub
          to='/orders'
          title={intl.formatMessage({id: 'MENU.ORDERS'})}
          icon='/media/icons/duotune/files/fil001.svg'
        >
          {findRoleUser('orders-add') && (
            <AsideMenuItem
              to='/orders/create-order'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.ORDERS_NEW'})}
            />
          )}
          {findRoleUser('orders-view') && (
            <>
              <AsideMenuItem
                to='/orders'
                hasBullet={true}
                title={intl.formatMessage({id: 'MENU.ORDERS_LIST'})}
              />
              <AsideMenuItem
                to='/orders/archive'
                hasBullet={true}
                title={intl.formatMessage({id: 'MENU.ORDERS_ARCHIVE'})}
              />
            </>
          )}
        </AsideMenuItemWithSub>
      )}

      {findRoleUserByArray(['heat-map-view', 'couriers-map-view', 'couriers-map-view']) && (
        <AsideMenuItemWithSub
          to='/delivery'
          title={intl.formatMessage({id: 'MENU.DELIVERY'})}
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
        >
          {findRoleUser('heat-map-view') && (
            <AsideMenuItem
              to='/heat-map'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.HEAT_MAP'})}
            />
          )}

          {findRoleUser('couriers-map-view') && (
            <AsideMenuItem
              to='/couriers-map'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.COURIERS_MAP'})}
            />
          )}
          {findRoleUser('couriers-map-view') && (
            <AsideMenuItem
              to='/couriers'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.COURIERS'})}
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {findRoleUser('accounts-view') && (
        <AsideMenuItem
          to='/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.CLIENTS'})}
        />
      )}
      {findRoleUser('feedbacks-view') && (
        <AsideMenuItem
          to='/feedbacks'
          icon='/media/icons/duotune/general/gen011.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.FEEDBACKS'})}
        />
      )}

      {findRoleUserByArray([
        'groups-show',
        'notifications-view',
        'slider-view',
        'gallery-view',
        'news-view',
        'pages-view',
        'restaurants-view',
      ]) && (
        <AsideMenuItemWithSub
          to='/pages'
          title={intl.formatMessage({id: 'MENU.CONTENT'})}
          icon='/media/icons/duotune/layouts/lay008.svg'
        >
          {findRoleUser('groups-show') && (
            <AsideMenuItem
              to='/groups'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.MENU'})}
            />
          )}
          {findRoleUser('notifications-view') && (
            <AsideMenuItem
              to='/notifications'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}
            />
          )}
          {findRoleUser('slider-view') && (
            <AsideMenuItem
              to='/slider'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.SLIDER'})}
            />
          )}
          {/* {findRoleUser('gallery-view') && (
        <AsideMenuItem
          to='/gallery'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.GALLERY'})}
        />
      )} */}
          {/* {findRoleUser('news-view') && (
        <AsideMenuItem
          to='/news'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.NEWS'})}
        />
      )} */}
          {findRoleUser('pages-view') && (
            <AsideMenuItem
              to='/pages'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.PAGES'})}
            />
          )}
          {/* {findRoleUser('restaurants-view') && (
        <AsideMenuItem
          to='/restaurants'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.RESTAURANTS'})}
        />
      )} */}
        </AsideMenuItemWithSub>
      )}

      {findRoleUserByArray([
        'organizations-view',
        'branches-view',
        'users-view',
        'roles-view',
        'settings-view',
      ]) && (
        <AsideMenuItemWithSub
          to='/settings'
          title={intl.formatMessage({id: 'MENU.SETTINGS'})}
          icon='/media/icons/duotune/coding/cod001.svg'
        >
          {findRoleUser('organizations-view') && (
            <AsideMenuItem
              to='/organizations'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.ORGANIZATION'})}
            />
          )}
          {findRoleUser('branches-view') && (
            <AsideMenuItem
              to='/branches'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.BRANCHES'})}
            />
          )}
          {findRoleUser('branches-view') && (
            <AsideMenuItem
              to='/payment-types'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.PAYMENT_TYPES'})}
            />
          )}
          {findRoleUser('users-view') && (
            <AsideMenuItem
              to='/users'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.USERS'})}
            />
          )}
          {findRoleUser('roles-view') && (
            <AsideMenuItem
              to='/roles'
              title={intl.formatMessage({id: 'MENU.ROLES'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('settings-view') && (
            <AsideMenuItem
              to='/settings'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.SETTINGS'})}
            />
          )}
        </AsideMenuItemWithSub>
      )}
    </>
  )
}
