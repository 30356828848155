import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {getMediaUrl} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import {useRoleContext} from '../../../context/getAccessRoleUser'
import FooterCopyright from '../../../modules/FooterCopyright'

export function UserItemIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  let {id} = useParams()

  // LOCAL STATE
  const [data, setData] = React.useState<any>({})
  const [logs, setLogs] = React.useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  async function getData() {
    try {
      const res = await axios.get(`users/${id}`)
      setData(res.data || {})

      const resLogs = await axios.get(`users/logs/?user_id=${id}`)
      setLogs(resLogs.data.logs || {})

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.USERS'}),
        path: '/users',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {data?.name || intl.formatMessage({id: 'MENU.USER'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        {/* <div className='row main-overflow-x' style={{height: windowSize.current[1] - 180}}> */}
        <div className='row'>
          <div className='col-md-3'>
            <div className='card mb-5 mb-xl-8'>
              <div className='card-body'>
                <div className='d-flex flex-center flex-column py-5'>
                  <div className='symbol symbol-100px symbol-circle mb-7'>
                    <img
                      src={
                        data.image != ''
                          ? getMediaUrl('users', (data || {}).image)
                          : toAbsoluteUrl('/avatar.png')
                      }
                      alt={data?.name}
                    />
                  </div>
                  <div className='fs-3 text-gray-800  mb-3'>{data?.name}</div>
                  <div className='mb-8'>
                    <div className='badge badge-lg badge-light-primary d-inline'>
                      {data?.role?.title}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-stack fs-4 py-3'>
                  <div
                    className=' rotate collapsible'
                    data-bs-toggle='collapse'
                    // href='#kt_user_view_details'
                    role='button'
                    aria-expanded='false'
                    aria-controls='kt_user_view_details'
                  >
                    {intl.formatMessage({id: 'COMMON.DETAILS'})}
                  </div>
                  <div
                    className={clsx(
                      'badge badge-light-success ',
                      {'badge-light-success': data?.is_active},
                      {
                        'badge-light-danger': !data?.is_active,
                      }
                    )}
                  >
                    {data?.is_active
                      ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                      : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
                  </div>
                </div>
                <div className='separator' />
                <div id='kt_user_view_details' className='collapse show'>
                  <div className='pb-5 fs-6'>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.ACCOUNT_ID'})}</div>
                    <div className='text-gray-600'>ID-{data?.id}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.PHONE'})}</div>
                    <div className='text-gray-600'>
                      {data?.phone || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                    </div>
                    <div className=' mt-5'>Email</div>
                    <div className='text-gray-600'>
                      {data?.email || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                    </div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.LAST_VISIT'})}</div>
                    <div className='text-gray-600'>
                      {moment(data?.last_visit).format('MMMM Do YYYY, h:mm:ss a')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-9'>
            {/* <ul
              className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-4'
              role='tablist'
            >
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2 active'
                  data-bs-toggle='tab'
                  href='#kt_user_view_overview_events_and_logs_tab'
                  aria-selected='false'
                  role='tab'
                  tabIndex={-1}
                >
                  {intl.formatMessage({id: 'COMMON.EVENTS_AND_LOGS'})}
                </a>
              </li>
            </ul> */}
            <div className='tab-content' id='myTabContent'>
              <div
                className='tab-pane fade active show'
                id='kt_user_view_overview_events_and_logs_tab'
                role='tabpanel'
              >
                <div className='card main-overflow-x'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2>{intl.formatMessage({id: 'COMMON.LOGIN_SESSIONS'})}</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0 pb-5'>
                    <div className='table-responsive'>
                      <table
                        className='table align-middle table-row-dashed gy-5'
                        id='kt_table_users_login_session'
                      >
                        <thead className='border-bottom border-gray-200 fs-7 '>
                          <tr className='text-start text-muted text-uppercase gs-0'>
                            <th className='w-200px'>
                              {intl.formatMessage({id: 'COMMON.ACTIONS'})}
                            </th>
                            <th className='w-auto'>{intl.formatMessage({id: 'COMMON.DEVICE'})}</th>
                            <th>{intl.formatMessage({id: 'COMMON.IP_ADDRESS'})}</th>
                            <th className='min-w-125px'>
                              {intl.formatMessage({id: 'COMMON.DATE'})}
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fs-6text-gray-600'>
                          {logs?.map((log: any) => (
                            <tr>
                              <td>{log.action}</td>
                              <td>{log.device}</td>
                              <td>{log.ip_address}</td>
                              <td>{moment(log.created_at).format('YYYY-MM-DD')} </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
